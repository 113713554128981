import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
import { EncrdecrService } from './../Auth/encrdecr.service';
import { ConvertToJSONService } from './convert-to-json.service';
const headers = { headers: new HttpHeaders({ 'content-type': 'application/json' }) }

@Injectable({
  providedIn: 'root'
})
export class NseService {

  myDate = new Date();
  todaysDate = this.datePipe.transform(this.myDate, 'dd/MM/yyyy');
  ServerUrl = environment.ServerUrl;

  constructor(private http: HttpClient, private datePipe: DatePipe, private EncrdecrService: EncrdecrService,
    private ConvertToJSON: ConvertToJSONService) { }

  GetToken() {

    let token = sessionStorage.getItem('`huR,8@RK9^??b4 -.hfs!!uR,XX');
    let UserId = this.EncrdecrService.getDecr(sessionStorage.getItem('m5JkoXISmYRAIuY'));
    let User_Type = this.EncrdecrService.getDecr(sessionStorage.getItem('L2D3506kIHSk3E0'));
    let IpAddress = sessionStorage.getItem('Tgsh@#3734fjfskshh==');
    console.log("token :: "+token + "@" + UserId + "|" + User_Type + "|" + IpAddress);
    const TOKEN = btoa(token + "@" + UserId + "|" + User_Type + "|" + IpAddress);
    const tokenOption = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Basic ${TOKEN}`,
      })
    }
    return tokenOption;
  }
  GetTokenNonJSON() {
    let token = sessionStorage.getItem('`huR,8@RK9^??b4 -.hfs!!uR,XX');
    let UserId = this.EncrdecrService.getDecr(sessionStorage.getItem('m5JkoXISmYRAIuY'));
    let User_Type = this.EncrdecrService.getDecr(sessionStorage.getItem('L2D3506kIHSk3E0'));
    let IpAddress = sessionStorage.getItem('Tgsh@#3734fjfskshh==');
    const TOKEN = btoa(token + "@" + UserId + "|" + User_Type + "|" + IpAddress);
    const tokenOption = {
      headers: new HttpHeaders({
      'Authorization': `Basic ${TOKEN}`,
      })
    }
    return tokenOption;
  }

  //=======================================Common For All Transaction==================================================
  GetAllClient(UserType, SubBrokerId): Observable<any> {
    //UserType=1; SubBrokerId=1608; //TO
    //return this.http.get<any>(`${this.ServerUrl}/api/ReportFilter/DetailsNew?TRANS_TYPE=CLIENT_LIST&LOGIN_ID=${SubBrokerId}&USER_TYPE=${UserType}&ZONE_ID=&REGION_ID=&BRANCH_ID=&SB_ID=&EMP_ID=&FAMILY_ID=&CLIENT_ID=0`).pipe();
    const tokenOption = this.GetToken();
    const url = `${this.ServerUrl}/api/ReportFilter/DetailsNew`;
    const QueryString = `TRANS_TYPE=CLIENT_LIST&LOGIN_ID=${SubBrokerId}&USER_TYPE=${UserType}&ZONE_ID=&REGION_ID=&BRANCH_ID=&SB_ID=&EMP_ID=&FAMILY_ID=&CLIENT_ID=0`;
    const body = this.ConvertToJSON.QueryStringToJSON(QueryString);
    return this.http.post<any>(url, body, tokenOption).pipe();
  }
  Get_IIN_List(PAN): Observable<any> {
    //
    let s_url = `${this.ServerUrl}/api/DropDownNSE/Details?TRANS_TYPE=IIN&REFF_ID=${PAN}&CON_STR=OptiCloud`;

    const url = s_url.split('?')[0];
    const QueryString = s_url.split('?')[1];
    const body = this.ConvertToJSON.QueryStringToJSONWithEncryptedValue(QueryString);

    return this.http.post<any>(url, body, this.GetToken()).pipe();
  }
  Get_IIN_ListBSE(PAN): Observable<any> {
    //
    debugger;
    let s_url = `${this.ServerUrl}/api/DropDownNSE/Details?TRANS_TYPE=IINBSE&REFF_ID=${PAN}&CON_STR=OptiCloud`;
    // return this.http.get<any>(url);
    const tokenOption = this.GetToken();
    const url = s_url.split('?')[0];
    const QueryString = s_url.split('?')[1];
    const body = this.ConvertToJSON.QueryStringToJSONWithEncryptedValue(QueryString);
    console.log("get IIn url"+url);
    console.log("get IIn body"+JSON.stringify(body));
    return this.http.post<any>(url, body, tokenOption).pipe();
  }

  GetAMCList(): Observable<any> {
    let s_url = `${this.ServerUrl}/api/DropDownNSE/Details?TRANS_TYPE=FundHouse_list_Amc_NSE&CON_STR=OptiMF`;
    const tokenOption = this.GetToken();
    const url = s_url.split('?')[0];
    const QueryString = s_url.split('?')[1];
    const body = this.ConvertToJSON.QueryStringToJSONWithEncryptedValue(QueryString);

    return this.http.post<any>(url, body, tokenOption).pipe();
  }

  GetSchemeList(AMCCode): Observable<any> {

    let s_url = `${this.ServerUrl}/api/DropDownNSE/Details?TRANS_TYPE=SchemeList_NSE&REFF_ID=${AMCCode}&CON_STR=OptiMF`;
    const tokenOption = this.GetToken();
    const url = s_url.split('?')[0];
    const QueryString = s_url.split('?')[1];
    const body = this.ConvertToJSON.QueryStringToJSONWithEncryptedValue(QueryString);
    return this.http.post<any>(url, body, tokenOption).pipe();
  }
  GetSelectedSchemeData(AMCCode, SchemeCode,Flag): Observable<any> {
    //return this.http.get<any>(`${this.ServerUrl}/api/NSEGetMasterData/GetSchemes?AMCCode=${AMCCode}&FundCategory=&SchemeCode=${SchemeCode}`).pipe();
    let s_url = `${this.ServerUrl}/api/NSEGetMasterData/GetSchemes?AMCCode=${AMCCode}&FundCategory=&SchemeCode=${SchemeCode}&Flag=${Flag}`;
    const tokenOption = this.GetToken();
    const url = s_url.split('?')[0];
    const QueryString = s_url.split('?')[1];
    const body = this.ConvertToJSON.QueryStringToJSON(QueryString);
    return this.http.post<any>(url, body, tokenOption).pipe();
  }

  SchemeAdditionalDetails(productCode, Trxn_type, AMCCode): Observable<any> {
    //return this.http.get<any>(`${this.ServerUrl}/api/IINBankDetails/GetAdditionalBankMandateReport_NSE?productCode=${productCode}&Trxn_type=${Trxn_type}&AMCCode=${AMCCode}`);
    let s_url = `${this.ServerUrl}/api/IINBankDetails/GetAdditionalBankMandateReport_NSE?productCode=${productCode}&Trxn_type=${Trxn_type}&AMCCode=${AMCCode}`;
    const tokenOption = this.GetToken();
    const url = s_url.split('?')[0];
    const QueryString = s_url.split('?')[1];
    const body = this.ConvertToJSON.QueryStringToJSON(QueryString);
    return this.http.post<any>(url, body, tokenOption).pipe();
  }

  BindExistingFolio(ClientId, ProductCode, AmcCode): Observable<any> {

    let s_url = `${this.ServerUrl}/api/DropDownNSE/Details?TRANS_TYPE=FolioNoNse&REFF_ID=${ClientId}&CON_STR=OptiMF&REFF_ID2=${ProductCode}&REFF_ID3=${AmcCode}`;
    const tokenOption = this.GetToken();
    const url = s_url.split('?')[0];
    const QueryString = s_url.split('?')[1];
    const body = this.ConvertToJSON.QueryStringToJSONWithEncryptedValue(QueryString);
    return this.http.post<any>(url, body, tokenOption).pipe();
  }

  BindExistingFolioScheme(ClientId, ProductCode, AmcCode): Observable<any> {

    let s_url = `${this.ServerUrl}/api/DropDownNSE/Details?TRANS_TYPE=FolioNoNseScheme&REFF_ID=${ClientId}&CON_STR=OptiMF&REFF_ID2=${ProductCode}&REFF_ID3=${AmcCode}`;
    const tokenOption = this.GetToken();
    const url = s_url.split('?')[0];
    const QueryString = s_url.split('?')[1];
    const body = this.ConvertToJSON.QueryStringToJSONWithEncryptedValue(QueryString);
    return this.http.post<any>(url, body, tokenOption).pipe();
  }

  //used for lumpsum and sip
  GetPaymentModeList(): Observable<any> {

    let s_url = `${this.ServerUrl}/api/DropDownNSE/Details?TRANS_TYPE=PaymentModeNSE&CON_STR=OptiCloud`;
    const tokenOption = this.GetToken();
    const url = s_url.split('?')[0];
    const QueryString = s_url.split('?')[1];
    const body = this.ConvertToJSON.QueryStringToJSONWithEncryptedValue(QueryString);
    return this.http.post<any>(url, body, tokenOption).pipe();
  }
  GetBankNameList(ClientId): Observable<any> {

    let s_url = `${this.ServerUrl}/api/DropDownNSE/Details?TRANS_TYPE=Bank_list&REFF_ID=${ClientId}&CON_STR=OptiCloud`;
    const tokenOption = this.GetToken();
    const url = s_url.split('?')[0];
    const QueryString = s_url.split('?')[1];
    const body = this.ConvertToJSON.QueryStringToJSONWithEncryptedValue(QueryString);
    console.log(" GetBankNameList Request url is"+url);
    console.log("GetBankNameList Request body is"+JSON.stringify(body));
    return this.http.post<any>(url, body, tokenOption).pipe();
  }
  Get_UMRN_BankNameList(IIN): Observable<any> {

    let s_url = `${this.ServerUrl}/api/DropDownNSE/Details?TRANS_TYPE=UMRNNo&REFF_ID=${IIN}&CON_STR=OptiCloud`;
    const tokenOption = this.GetToken();
    const url = s_url.split('?')[0];
    const QueryString = s_url.split('?')[1];
    const body = this.ConvertToJSON.QueryStringToJSONWithEncryptedValue(QueryString);
    return this.http.post<any>(url, body, tokenOption).pipe();
  }

  DownloadCheque(formData) {
    const tokenOption = this.GetToken();
    return this.http.post<any>(`${this.ServerUrl}/api/NSEMutualFundPDF/DownloadNSEPDFForm`, formData, tokenOption);
  }

  //AMC Code based Scheme List with AssetType
  // GetSchemeList(AMCCode): Observable<any> {
  //   `http://localhost:49522/api/DropDown/Details?TRANS_TYPE=SchemeList_NSE&REFF_ID=107&CON_STR=OptiMF&REFF_ID2=Equity`
  //   return this.http.get<any>(`${this.ServerUrl}/api/DropDown/Details?TRANS_TYPE=SchemeList_NSE&REFF_ID=107&CON_STR=OptiMF`).pipe();
  // }

  //=======================================FOR LUMPSUM==================================================
  InvestToLumpsum(formData): Observable<any> {
    const tokenOption = this.GetToken();
    return this.http.post<any>(`${this.ServerUrl}/api/lumsumSIP/Details`, formData, tokenOption);
  }


  //=======================================FOR SIP==================================================
  GetMandateList(ClientId, IIN): Observable<any> {

    let s_url = `${this.ServerUrl}/api/DropDownNSE/Details?TRANS_TYPE=ClientACHMandateList&REFF_ID=${ClientId}&CON_STR=OptiCloud&REFF_ID2=SI&REFF_ID3=${IIN}`;
    const url = s_url.split('?')[0];
    const QueryString = s_url.split('?')[1];
    const body = this.ConvertToJSON.QueryStringToJSONWithEncryptedValue(QueryString);
    return this.http.post<any>(url, body, this.GetToken()).pipe();
  }
  //Physical mandate
  GenerateMandate(formData): Observable<any> {
    const tokenOption = this.GetToken();
    return this.http.post<any>(`${this.ServerUrl}/api/NSEClientMandate/MandateRegistration`, formData, tokenOption).pipe();
  }

  GetSIPFrequencyList(frequency) {
    let s_url = `${this.ServerUrl}/api/DropDownNSE/Details?TRANS_TYPE=SIP_Days_Frequency&REFF_ID=${frequency}&CON_STR=OptiCloud`;
    const tokenOption = this.GetToken();
    const url = s_url.split('?')[0];
    const QueryString = s_url.split('?')[1];
    const body = this.ConvertToJSON.QueryStringToJSONWithEncryptedValue(QueryString);
    return this.http.post<any>(url, body, tokenOption).pipe();
  }
  InvestToSIP(formData): Observable<any> {
    //`http://localhost:49522/api/lumsumSIP/Details`
    const tokenOption = this.GetToken();
    return this.http.post<any>(`${this.ServerUrl}/api/lumsumSIP/Details`, formData, tokenOption);
  }


  //get sip frequency Discuss dynamic or static
  //min sip amt we are not showing only checking
  //

  //=======================================SIP End==================================================

  //used in switch, redeem, swp, stp
  // GetFromScheme(ClientBasicInfoId): Observable<any>{
  //   return this.http.get<any>(`${this.ServerUrl}/api/BSETransaction/SourceSchemeDetails?ClientBasicInfoId=${ClientBasicInfoId}&Flag=ClientWiseExistingScheme`).pipe();
  // }

  GetHoldingSchemes(ClientId,Flag) {
    //return this.http.get<any>(`${this.ServerUrl}/api/MyHolding/MyHoldingnNSE?ClientBasicInfoIdList=${ClientId}&SchemeCode=0&FolioNumber=0`)
    let s_url = `${this.ServerUrl}/api/MyHolding/MyHoldingnNSE?ClientBasicInfoIdList=${ClientId}&SchemeCode=0&FolioNumber=0&Flag=${Flag}`;
    const tokenOption = this.GetToken();
    const url = s_url.split('?')[0];
    const QueryString = s_url.split('?')[1];
    const body = this.ConvertToJSON.QueryStringToJSON(QueryString);
    return this.http.post<any>(url, body, tokenOption).pipe();
  }

  //used in switch, redeem, swp, stp
  GetInvestedFolio(ClientBasicInfoId, product_code, SchemeCode): Observable<any> {
    let s_url = `${this.ServerUrl}/api/DropDown/Details?TRANS_TYPE=FolioNoNse&REFF_ID=${ClientBasicInfoId}&CON_STR=OptiMF&REFF_ID2=${product_code}&REFF_ID3=${SchemeCode}`;
    //return this.http.get<any>(Url);
    const tokenOption = this.GetToken();
    const url = s_url.split('?')[0];
    const QueryString = s_url.split('?')[1];
    const body = this.ConvertToJSON.QueryStringToJSON(QueryString);
    return this.http.post<any>(url, body, tokenOption).pipe();
  }
  //used in switch, swp, stp
  IsDivOption(SchemeCode): Observable<any> {
    //return this.http.get<any>(`${this.ServerUrl}/api/DropDown/DividentDetails?Flag=AccordDividentOption&SchemeCode=${SchemeCode}`).pipe();
    let s_url = `${this.ServerUrl}/api/DropDown/DividentDetails?Flag=AccordDividentOption&SchemeCode=${SchemeCode}`;
    const tokenOption = this.GetToken();
    const url = s_url.split('?')[0];
    const QueryString = s_url.split('?')[1];
    const body = this.ConvertToJSON.QueryStringToJSON(QueryString);
    return this.http.post<any>(url, body, tokenOption).pipe();
  }
  //used in swp, stp
  GetFrequency(): Observable<any> {
    //return this.http.get<any>(`${this.ServerUrl}/api/DropDown/Details?Flag=BSESTPSWPFrequency`).pipe();
    let s_url = `${this.ServerUrl}/api/DropDown/Details?Flag=BSESTPSWPFrequency`;
    const tokenOption = this.GetToken();
    const url = s_url.split('?')[0];
    const QueryString = s_url.split('?')[1];
    const body = this.ConvertToJSON.QueryStringToJSON(QueryString);
    return this.http.post<any>(url, body, tokenOption).pipe();
  }
  //used in swp, stp
  Get_STP_SWP_MinAmt(SchemeCode, TransType): Observable<any> {
    //return this.http.get<any>(`${this.ServerUrl}/api/BSESIPDetails/GetBSESIPDetails?BSESchemeCode=${SchemeCode}&Flag=STPSWPDetails&TRANS_TYPE=${TransType}`).pipe();
    let s_url = `${this.ServerUrl}/api/BSESIPDetails/GetBSESIPDetails?BSESchemeCode=${SchemeCode}&Flag=STPSWPDetails&TRANS_TYPE=${TransType}`;
    const tokenOption = this.GetToken();
    const url = s_url.split('?')[0];
    const QueryString = s_url.split('?')[1];
    const body = this.ConvertToJSON.QueryStringToJSON(QueryString);
    return this.http.post<any>(url, body, tokenOption).pipe();
  }

  //=======================================FOR SWITCH==================================================
  SwitchToScheme(AMCCode) {
    let s_url = `${this.ServerUrl}/api/DropDownNSE/Details?TRANS_TYPE=SchemeList_NSE_SwitchAllow&REFF_ID=${AMCCode}&CON_STR=OptiMF`;
    const tokenOption = this.GetToken();
    const url = s_url.split('?')[0];
    const QueryString = s_url.split('?')[1];
    const body = this.ConvertToJSON.QueryStringToJSONWithEncryptedValue(QueryString);
    return this.http.post<any>(url, body, tokenOption).pipe();
  }
  SwitchMinimumAmount(product_long_name, AMCCode) {
    let s_url = `${this.ServerUrl}/api/MyHolding/MSTProductlimit?product_long_name=${product_long_name}&Trxn_type=SI&AMCCode=${AMCCode}`;
    //return this.http.get<any>(url);
    const tokenOption = this.GetToken();
    const url = s_url.split('?')[0];
    const QueryString = s_url.split('?')[1];
    const body = this.ConvertToJSON.QueryStringToJSON(QueryString);
    return this.http.post<any>(url,body, tokenOption).pipe();
  }
  InvestToSwitch(formData): Observable<any> {
    //`http://localhost:49522/api/Switch/SwitchTransaction`
    const tokenOption = this.GetToken();
    return this.http.post<any>(`${this.ServerUrl}/api/Switch/SwitchTransaction`, formData, tokenOption);
  }






  //=======================================FOR Redemption==================================================
  GetBankListRedemption(ClientId): Observable<any> {
    //return this.http.get<any>(`${this.ServerUrl}/api/DropDown/Details?TRANS_TYPE=Bank_list&REFF_ID=${ClientId}&CON_STR=OptiCloud`);

    let s_url = `${this.ServerUrl}/api/DropDown/Details?TRANS_TYPE=Bank_list&REFF_ID=${ClientId}&CON_STR=OptiCloud`;
    const tokenOption = this.GetToken();
    const url = s_url.split('?')[0];
    const QueryString = s_url.split('?')[1];
    const body = this.ConvertToJSON.QueryStringToJSON(QueryString);
    return this.http.post<any>(url, body, tokenOption).pipe();
  }
  ProductLimitAPI(product_long_name, AMCCode) {
    //return this.http.get<any>(`${this.ServerUrl}/api/MyHolding/MSTProductlimit?product_long_name=${product_long_name}&Trxn_type=R&AMCCode=${AMCCode}`);

    let s_url = `${this.ServerUrl}/api/MyHolding/MSTProductlimit?product_long_name=${product_long_name}&Trxn_type=R&AMCCode=${AMCCode}`;
    const tokenOption = this.GetToken();
    const url = s_url.split('?')[0];
    const QueryString = s_url.split('?')[1];
    const body = this.ConvertToJSON.QueryStringToJSON(QueryString);
    return this.http.post<any>(url, body, tokenOption).pipe();
  }
  InitiateRedemption(formData): Observable<any> {
    //`http://localhost:49522/api/RedemptionNSE/Redemption`
    const tokenOption = this.GetToken();
    return this.http.post<any>(`${this.ServerUrl}/api/RedemptionNSE/Redemption`, formData, tokenOption);
  }






  //=======================================FOR SWP==================================================
  InvestToSWP(formData): Observable<any> {
    //`http://localhost:49522/api/SWP/SWPTransaction`
    const tokenOption = this.GetToken();
    return this.http.post<any>(`${this.ServerUrl}/api/SWP/SWPTransaction`, formData, tokenOption);
  }
  ProductlimitSWP(product_long_name, AMCCode) {
    let s_url = `${this.ServerUrl}/api/MyHolding/MSTProductlimit?product_long_name=${product_long_name}&Trxn_type=SO&AMCCode=${AMCCode}`;
    //return this.http.get<any>(url);
    const tokenOption = this.GetToken();
    const url = s_url.split('?')[0];
    const QueryString = s_url.split('?')[1];
    const body = this.ConvertToJSON.QueryStringToJSON(QueryString);
    let str = JSON.stringify(body);
    return this.http.post<any>(url,body, tokenOption).pipe();
  }



  //=======================================FOR STP==================================================
  // GetExistingTargetScheme(ClientBasicInfoId,Schemecode,FolioNumber): Observable<any> {
  //   return this.http.get<any>(`${this.ServerUrl}/api/BSETransaction/SourceSchemeDetails?ClientBasicInfoId=${ClientBasicInfoId}&Flag=ClientSchemeByFolioNo&SchemeCode=${Schemecode}&FolioNumber=${FolioNumber}`).pipe();
  // }

  GetSTPFrequency() {
    let s_url = `${this.ServerUrl}/api/DropDownNSE/Details?TRANS_TYPE=SIP_Days_Frequency&REFF_ID=OM,OW&CON_STR=OptiCloud`;
    const tokenOption = this.GetToken();
    const url = s_url.split('?')[0];
    const QueryString = s_url.split('?')[1];
    const body = this.ConvertToJSON.QueryStringToJSONWithEncryptedValue(QueryString);
    return this.http.post<any>(url, body, tokenOption).pipe();
  }

  InvestToSTP(formData): Observable<any> {
    //`http://localhost:49522/api/STP/STPTransaction`
    const tokenOption = this.GetToken();
    return this.http.post<any>(`${this.ServerUrl}/api/STP/STPTransaction`, formData, tokenOption);
  }

  ProductlimitSTP(product_long_name, AMCCode) {
    let s_url = `${this.ServerUrl}/api/MyHolding/MSTProductlimit?product_long_name=${product_long_name}&Trxn_type=SI&AMCCode=${AMCCode}`;
    //return this.http.get<any>(url);
    const tokenOption = this.GetToken();
    const url = s_url.split('?')[0];
    const QueryString = s_url.split('?')[1];
    const body = this.ConvertToJSON.QueryStringToJSON(QueryString);
    return this.http.post<any>(url, body, tokenOption).pipe();
  }

  //-----------------------

  AddToCart(formData) {
    const tokenOption = this.GetToken();
    return this.http.post<any>(`${this.ServerUrl}/api/CartSummary/Details`, formData, tokenOption);
  }
  CartClientNameList(UserType, SubbrokerCode) {
    let s_url = `${this.ServerUrl}/api/DropDownNSE/Details?TRANS_TYPE=CartSummaryClient&REFF_ID=${SubbrokerCode}&CON_STR=OptiCloud&REFF_ID2=${UserType}`;
    //return this.http.get<any>(url);
    const tokenOption = this.GetToken();
    const url = s_url.split('?')[0];
    const QueryString = s_url.split('?')[1];
    const body = this.ConvertToJSON.QueryStringToJSONWithEncryptedValue(QueryString);
    return this.http.post<any>(url, body, tokenOption).pipe();
  }
  CartTransactionTypeList(ClientBasicInfoId, UccCode) {
    let s_url = `${this.ServerUrl}/api/CartSummary/Details_NSE?Flag=SelectAll&ClientBasicInfoId=${ClientBasicInfoId}&UccCode=${UccCode}&TRANS_TYPE=TransactionType`;
    //return this.http.get<any>(url);
    const tokenOption = this.GetToken();
    const url = s_url.split('?')[0];
    const QueryString = s_url.split('?')[1];
    const body = this.ConvertToJSON.QueryStringToJSON(QueryString);
    return this.http.post<any>(url, body, tokenOption).pipe();
  }
  CartBankNameList(ClientBasicInfoId, UccCode, PaymentMode, TransPlanMode, unique_ref_no) {
    let s_url = `${this.ServerUrl}/api/CartSummary/Details_NSE?Flag=SelectAll&ClientBasicInfoId=${ClientBasicInfoId}&UccCode=${UccCode}&TRANS_TYPE=BankName&PaymentMode=${PaymentMode}&TransPlanMode=${TransPlanMode}&unique_ref_no=${unique_ref_no}`;
    //return this.http.get<any>(url);
    const tokenOption = this.GetToken();
    const url = s_url.split('?')[0];
    const QueryString = s_url.split('?')[1];
    const body = this.ConvertToJSON.QueryStringToJSON(QueryString);
    return this.http.post<any>(url, body, tokenOption).pipe();
  }
  CartSummaryList(ClientBasicInfoId, SUB_BROKER_CODE, TransPlanMode, PaymentMode, unique_ref_no, BankName, UccCode) {
    let s_url = `${this.ServerUrl}/api/CartSummary/Details_NSE?Flag=SelectAll&ClientBasicInfoId=${ClientBasicInfoId}&SUB_BROKER_CODE=${SUB_BROKER_CODE}&TransPlanMode=${TransPlanMode}&PaymentMode=${PaymentMode}&unique_ref_no=${unique_ref_no}&BankName=${BankName}&UccCode=${UccCode}`;
    //return this.http.get<any>(url);
    const tokenOption = this.GetToken();
    const url = s_url.split('?')[0];
    const QueryString = s_url.split('?')[1];
    const body = this.ConvertToJSON.QueryStringToJSON(QueryString);
    return this.http.post<any>(url, body, tokenOption).pipe();
  }
  
  CartSummnaryCount(ClientBasicInfoId){
    let s_url = `${this.ServerUrl}/api/CartSummary/CountCart?ClientBasicInfoId=${ClientBasicInfoId}`;
    const tokenOption = this.GetToken();
    const url = s_url.split('?')[0];
    const QueryString = s_url.split('?')[1];
    const body = this.ConvertToJSON.QueryStringToJSON(QueryString);
    return this.http.post<any>(url, body, tokenOption).pipe();
  }
  CartDeleteItems(formData) {
    const tokenOption = this.GetToken();
    return this.http.post<any>(`${this.ServerUrl}/api/CartSummary/Details`, formData, tokenOption);
  }
  CartUpdateItem(formData) {
    const tokenOption = this.GetToken();
    let url = `${this.ServerUrl}/api/CartSummary/UpdateCartDetails`;
    return this.http.post<any>(url, formData, tokenOption);
  }
  // BillDeskBankDetails(ClientBasicInfoId, BankID) {
  //   let s_url = `${this.ServerUrl}/api/NSETransactionCommon/GetBillDeskBankDetails?ClientBasicInfoId=${ClientBasicInfoId}&BankID=${BankID}`;
  //   //return this.http.get<any>(url);
  //   const tokenOption = this.GetToken();
  //   const url = s_url.split('?')[0];
  //   const QueryString = s_url.split('?')[1];
  //   const body = this.ConvertToJSON.QueryStringToJSON(QueryString);
  //   return this.http.get<any>(s_url, tokenOption).pipe();
  // }

  BillDeskBankDetails(ClientBasicInfoId, BankID) {
    let s_url = `${this.ServerUrl}/api/NSETransactionCommon/GetBillDeskBankDetails?ClientBasicInfoId=${ClientBasicInfoId}&BankID=${BankID}`;
    //return this.http.get<any>(url);
    const tokenOption = this.GetToken();
    const url = s_url.split('?')[0];
    const QueryString = s_url.split('?')[1];
    const body = this.ConvertToJSON.QueryStringToJSON(QueryString);
    return this.http.post<any>(url,body, tokenOption).pipe();
  }
  CartProceedTransaction(formData) {
    const tokenOption = this.GetToken();
    let url = `${this.ServerUrl}/api/NSEPurchaseTransaction/SaveTransaction`;
    return this.http.post<any>(url, formData, tokenOption);
  }
  ConvertDate(IpDate: string) {
    let date = IpDate.replace(" 00:00:00", "").split('/');
    let finalDate = this.datePipe.transform(date[1] + '/' + date[0] + '/' + date[2], 'dd-MMM-yyyy');
    return finalDate;
  }

  //#region explore Funds Services
  // GetExploreFundsData() {
  //   let url = `${this.ServerUrl}/api/NSESchemeList/SchemeList`;
  //   return this.http.get<any[]>(url).pipe();
  // }


  //----------------------Goal Planning------------------------

  // RiskProfileDropdownList() {
  //   let url = `${this.ServerUrl}/api/InvestGoalPortfolio_Mahindra/GetRiskProfiles`;
  //   //return this.http.get<any>(url);
  //    const tokenOption = this.GetToken();
  //   // const url = s_url.split('?')[0];
  //   // const QueryString = s_url.split('?')[1];
  //   // const body = this.ConvertToJSON.QueryStringToJSON(QueryString);
  //   return this.http.get<any>(url, tokenOption).pipe();
  // }

  RiskProfileDropdownList() {
    let s_url = `${this.ServerUrl}/api/InvestGoalPortfolio_Mahindra/GetRiskProfiles`;
    //return this.http.get<any>(url);
     const tokenOption = this.GetToken();
    //const url = s_url.split('?')[0];
    //const QueryString = s_url.split('?')[1];
    //const body = this.ConvertToJSON.QueryStringToJSON(QueryString);
    return this.http.post<any>(s_url, null,tokenOption).pipe();
  }

  // GetClientRiskProfile(ClientBasicInfoId) {
  //   let url = `${this.ServerUrl}/api/ClientRiskProfile_QuestionAnswer/GetClientRiskProfileNameAndId?ClientBasicInfoId=${ClientBasicInfoId}`;
  //   //return this.http.get<any>(url);
  //   const tokenOption = this.GetToken();
  //   // const url = s_url.split('?')[0];
  //   // const QueryString = s_url.split('?')[1];
  //   // const body = this.ConvertToJSON.QueryStringToJSON(QueryString);
  //   return this.http.get<any>(url, tokenOption).pipe();
  // }


  GetClientRiskProfile(ClientBasicInfoId) {
    let s_url = `${this.ServerUrl}/api/ClientRiskProfile_QuestionAnswer/GetClientRiskProfileNameAndId?ClientBasicInfoId=${ClientBasicInfoId}`;
    //return this.http.get<any>(url);
    const tokenOption = this.GetToken();
    const url = s_url.split('?')[0];
    const QueryString = s_url.split('?')[1];
    const body = this.ConvertToJSON.QueryStringToJSON(QueryString);
    return this.http.post<any>(url,body, tokenOption).pipe();
  }
  // GetPortfolioList(RiskProfile, Amount, Tenure, TRANS_TYPE) {
  //   let s_url = `${this.ServerUrl}/api/InvestGoalPortfolio_Mahindra/GetDetails?Flag=&Wealthmfid=0&RiskProfile=${RiskProfile}&Amount=${Amount}&Tenure=${Tenure}&AmountRangeId=0&TRANS_TYPE=${TRANS_TYPE}`;
  //   //return this.http.get<any>(url);

  //   const tokenOption = this.GetToken();
  //   const url = s_url.split('?')[0];
  //   const QueryString = s_url.split('?')[1];
  //   const body = this.ConvertToJSON.QueryStringToJSON(QueryString);
  //   return this.http.get<any>(s_url, tokenOption).pipe();
  // }

  GetPortfolioList(RiskProfile, Amount, Tenure, TRANS_TYPE) {
    let s_url = `${this.ServerUrl}/api/InvestGoalPortfolio_Mahindra/GetDetails?Flag=&Wealthmfid=0&RiskProfile=${RiskProfile}&Amount=${Amount}&Tenure=${Tenure}&AmountRangeId=0&TRANS_TYPE=${TRANS_TYPE}`;
    //return this.http.get<any>(url);

    const tokenOption = this.GetToken();
    const url = s_url.split('?')[0];
    const QueryString = s_url.split('?')[1];
    const body = this.ConvertToJSON.QueryStringToJSON(QueryString);
    return this.http.post<any>(url,body, tokenOption).pipe();
  }

  //----------------------Goal Planning------------------------
  HoldingReportTransactionOption(SchemeCode, DividendOption, ReInvestment) {
    let s_url = `${this.ServerUrl}/api/NSETransactionCommon/GetCamsCodeBySchemeCodeNSE?SchemeCode=${SchemeCode}&DividendOption=${DividendOption}&ReInvestment=${ReInvestment}`;
    //return this.http.get<any>(Url, headers);
    const tokenOption = this.GetToken();
    const url = s_url.split('?')[0];
    const QueryString = s_url.split('?')[1];
    const body = this.ConvertToJSON.QueryStringToJSON(QueryString);
    return this.http.post<any>(url, body, tokenOption).pipe();
  }
  Get_DividendFlag(REFF_ID, REFF_ID2) {
    let s_url = `${this.ServerUrl}/api/DropDownNSE/Details?TRANS_TYPE=FolioWiseDividendFlag&REFF_ID=${REFF_ID}&REFF_ID2=${REFF_ID2}&CON_STR=OptiMF`;
    //return this.http.get<any>(Url, headers);
    const tokenOption = this.GetToken();
    const url = s_url.split('?')[0];
    const QueryString = s_url.split('?')[1];
    const body = this.ConvertToJSON.QueryStringToJSONWithEncryptedValue(QueryString);
    return this.http.post<any>(url, body, tokenOption).pipe();
  }
  // getClientACHUniqueReferenceNo(IIN, UniqueNo) {
  //   let s_url = `${this.ServerUrl}/api/NSETransactionCommon/GetClientACHMandate_NSE_UniqueRefNumber?IIN=${IIN}&unique_ref_no=${UniqueNo}`;
  //   //return this.http.get<any>(Url, headers);
  //   const tokenOption = this.GetToken();
  //   const url = s_url.split('?')[0];
  //   const QueryString = s_url.split('?')[1];
  //   const body = this.ConvertToJSON.QueryStringToJSON(QueryString);
  //   //return this.http.post<any>(url, body, tokenOption).pipe();
  //   return this.http.get<any>(s_url, tokenOption).pipe();
  // }


  getClientACHUniqueReferenceNo(IIN, UniqueNo) {
    let s_url = `${this.ServerUrl}/api/NSETransactionCommon/GetClientACHMandate_NSE_UniqueRefNumber?IIN=${IIN}&unique_ref_no=${UniqueNo}`;
    //return this.http.get<any>(Url, headers);
    const tokenOption = this.GetToken();
    const url = s_url.split('?')[0];
    const QueryString = s_url.split('?')[1];
    const body = this.ConvertToJSON.QueryStringToJSON(QueryString);
    return this.http.post<any>(url, body, tokenOption).pipe();
    //return this.http.get<any>(s_url, tokenOption).pipe();
  }

  GetFrequencyNew(AMCCode,SchemeCode,Flag): Observable<any> {

    let s_url = `${this.ServerUrl}/api/MyHolding/MyHoldingnNSE_Frequency?AMCCode=${AMCCode}&SchemeCode=${SchemeCode}&Flag=${Flag}`;
    const tokenOption = this.GetToken();
    const url = s_url.split('?')[0];
    const QueryString = s_url.split('?')[1];
    const body = this.ConvertToJSON.QueryStringToJSON(QueryString);
    return this.http.post<any>(url, body, tokenOption).pipe();
  }
  GetIINUploadDocument(): Observable<any> {
    const s_url =`${this.ServerUrl}/api/DropDown/ReportDetails?TRANS_TYPE=NSE_DOC`;
    const tokenOption = this.GetToken();
    const url = s_url.split('?')[0];
    const QueryString = s_url.split('?')[1];
    const body = this.ConvertToJSON.QueryStringToJSON(QueryString);

    const req = {
      data:this.EncrdecrService.setEncr(JSON.stringify(body))
    }

    return this.http.post<any>(url,req, tokenOption).pipe();
  }


  PostIINUploadDocumnt(formData): Observable<any> {
    let DataUrl = `${this.ServerUrl}/api/NSEUploadImage/UploadbyteImageAOF`;
    console.log(DataUrl);
    const header = this.GetTokenNonJSON();
    return this.http.post<any>(DataUrl, formData,header);
  }

  // GetIINUploadDetails(USER_ID,USER_TYPE,PANNo): Observable<any> {
  //   const s_url =`${this.ServerUrl}/api/NSEUploadImage/GetDetails?USER_ID=${USER_ID}&USER_TYPE=${USER_TYPE}&PANNo=${PANNo}`;
  //   const tokenOption = this.GetToken();
  //   const url = s_url.split('?')[0];
  //   const QueryString = s_url.split('?')[1];
  //   const body = this.ConvertToJSON.QueryStringToJSON(QueryString);
  //   return this.http.get<any>(s_url, tokenOption).pipe();
  // }

  GetIINUploadDetails(USER_ID,USER_TYPE,PANNo): Observable<any> {
    const s_url =`${this.ServerUrl}/api/NSEUploadImage/GetDetails?USER_ID=${USER_ID}&USER_TYPE=${USER_TYPE}&PANNo=${PANNo}`;
    const tokenOption = this.GetToken();
    const url = s_url.split('?')[0];
    const QueryString = s_url.split('?')[1];
    const body = this.ConvertToJSON.QueryStringToJSON(QueryString);
    return this.http.post<any>(url,body, tokenOption).pipe();
  }

  SendingHoldingReportTOClient(Basestring,Clientid): Observable<any> {

    const s_url =`${this.ServerUrl}/api/CommonOTP/SendEmail`;
    const tokenOption = this.GetToken();
   const request ={
    Base64String:Basestring,
    ClientBasicinfoId:Clientid
   }
   console.log(Basestring);
    return this.http.post<any>(s_url,request, tokenOption).pipe();
  }
}
