<div *ngIf="isLoggedIn$ | async as isShowHeader">
  <nav class="navbar bg-white ShadowBottom fixed-top PostHeaderMobile">
    <div class="d-flex justify-content-between align-items-center w-100">
      <div class="d-flex align-items-center ABML_BG_White">
        <div *ngIf="!IsMobile" class="hamrburg CursorPointer" (click)="OnClickHamerburg()">
          <i class="fa fa-bars" aria-hidden="true" style="font-size: 17px;"></i>
        </div>
        <a class="navbar-brand p-0" *ngIf="!(CompanyName == 'ABML_Money' && IsMobile)">
          <img [src]='logo' [style.height]="logoHeight" alt="Logo" class="logo">
          <!-- <img src="../assets/images/ABMLband_Logo.jpg" alt="Logo" class="logo"> -->
        </a>
      </div>
      <div *ngIf="(CompanyName == 'Alankit' || CompanyName == 'NSDL') || (UserType == '3' && CompanyName == 'ABML_Money')" class="RightSideMenu">
        <!-- <img src="../../../assets/images/Notification.svg" class="HeaderIcons CursorPointer" /> -->
       
         <div class="cart-notify CursorPointer" routerLink="/transaction-new/cart">
          <img *ngIf="!(CompanyName == 'ABML_Money')" src="../../../assets/images/Cart.svg" class="HeaderIcons" 
          routerLinkActive='active' />
          <img *ngIf="(CompanyName == 'ABML_Money' && !IsMobile)" src="../../../assets/images/Cart_White.svg" class="HeaderIcons" 
          routerLinkActive='active' />
          <img *ngIf="(CompanyName == 'ABML_Money' && IsMobile)" src="../../../assets/images/Cart.svg" class="HeaderIcons" 
          routerLinkActive='active' />
           <span class="CartNoti" *ngIf="this.Count>0 && this.iscart">{{this.Count}}</span> 
         </div>
       
        <!-- <a *ngIf="!IsMobile && UserType != '1'" class="navbar-brand p-0 mr-0 ml-2">
          <img src="../../../assets/images/JM/IFD_Logo.png" alt="Logo" class="Companylogo_Web">
        </a> -->
      </div>

    </div>
  </nav>      
  <div class="MarginTopAsHeader FORScroll"> <!-- [ngClass]="CompanyName == 'ABML_Money' && isHidden ? 'MarginTopAfterScroll':''" -->
    <div *ngIf="CompanyName == 'ABML_Money' && !IsMobile" id="Second_Band" class="Second_Band fixed-top" >Aditya Birla Money Limited</div> <!--[ngClass]="isHidden ? 'hidden':''"-->
    <nav [ngClass]='IsMobile ? "d-flex":"d-none"' class="navbar fixed-bottom bg-white">
      <ul class="navbar-nav m-0 w-100 text-center">
        <li class="nav-item active" (click)="onClickFooterMenu('Home')" routerLinkActive='active'>
          <img alt="N/A" *ngIf="!IsHomeActive" src="../../../assets/images/JM/Home_Inactive.svg" class="" />
          <img alt="N/A" *ngIf="IsHomeActive" src="../../../assets/images/JM/Home_Active.svg" class="" />
          <a [ngClass]="IsHomeActive ? 'ClrRed_n':''" class="nav-link">Home</a>
        </li>
        <li class="nav-item" (click)="onClickFooterMenu('Act')" routerLink="/transaction-new/lumpsum"
          routerLinkActive='active'>
          <img alt="N/A" *ngIf="!IsActNowActive" src="../../../assets/images/JM/ActNow_Inactive.svg" class="" />
          <img alt="N/A" *ngIf="IsActNowActive" src="../../../assets/images/JM/ActNow_Active.svg" class="" />
          <a [ngClass]="IsActNowActive ? 'ClrRed_n':''" class="nav-link">Transact</a>
        </li>
        <li class="nav-item" (click)="onClickFooterMenu('Add')" routerLinkActive='active'>
          <img alt="N/A" src="../../../assets/images/JM/Menu-Plus.svg" class=" AddImg" />
          <!-- <img alt="N/A" *ngIf="IsAddActive" src="../../../assets/images/JM/Menu-Close.svg" class=" AddImg" /> -->
          <!-- <a class="nav-link"></a> -->
        </li>
        <li class="nav-item" (click)="onClickFooterMenu('Loyalty')" routerLink="/explore-funds-new"
          routerLinkActive='active'>
          <img alt="N/A" *ngIf="!IsLoyaltyActive" src="../../../assets/images/JM/Loyalty_Inactive.svg" class="" />
          <img alt="N/A" *ngIf="IsLoyaltyActive" src="../../../assets/images/JM/Loyalty_Active.svg" class="" />
          <a [ngClass]="IsLoyaltyActive ? 'ClrRed_n':''" class="nav-link">Explore Funds</a>
        </li>
        <li class="nav-item" id="IdOthersMenu" (click)="onClickFooterMenu('Menu')" routerLinkActive='active'>
          <img alt="N/A" src="../../../assets/images/JM/Menu_Inactive.svg" class="" />
          <!-- <img alt="N/A" *ngIf="IsMenuActive" src="../../../assets/images/JM/Menu_Active.svg" class="" /> -->
          <a class="nav-link">Menu</a>
        </li>
      </ul>
    </nav>
    <div id="overlay"></div>
    <ul id="OthersMenuUl" class="BoxShadow OthersMenuUl" style="display:none">
      <div class="MenuSection">
        <!-- <li class="nav-item" routerLink="/transaction-new/lumpsum" routerLinkActive='active'>
          <img alt="N/A" src="../../../assets/images/JM/Transact_new.svg" class="svg" width="24px" />
          <a class="nav-link">MF</a>
        </li> -->
        <li class="nav-item" routerLink="/clients" routerLinkActive='active' *ngIf="UserType != '3'">
          <img src="../../../assets/images/Sidebar/Clients.svg" class="svg" width="24px" />
          <a class="nav-link">Clients</a>
        </li>
         <li class="nav-item" routerLink="/fd/fd-landing" routerLinkActive='active' *ngIf="CompanyName == 'Alankit'">
          <img alt="N/A" src="../../../assets/images/Sidebar/FD.svg" class="svg" width="24px" />
          <a class="nav-link">FD</a>
        </li> 
        <li class="nav-item" routerLink="/SGB/SGBInvest" routerLinkActive='active' *ngIf="CompanyName == 'Alankit'">
          <img alt="N/A" src="../../../assets/images/Sidebar/SGB.svg" class="svg" width="24px" />
          <a class="nav-link">SGB</a>
        </li>
       <!-- <li class="nav-item" routerLink="ipo/dashboardmain" routerLinkActive='active'>
          <img alt="N/A" src="../../../assets/images/Sidebar/NCD.svg" class="svg" width="24px" />
          <a class="nav-link">IPO</a>
        </li>-->
        <li class="nav-item" routerLink="/goal-based-investing/dashboard" routerLinkActive='active' *ngIf="CompanyName != 'ABML_Money'">
          <img alt="N/A" src="../../../assets/images/JM/Goal Based Investing_new.svg" class="svg" width="24px" />
          <a class="nav-link">Goal Investing</a>
        </li>
        <li class="nav-item" routerLink="/ipo/index"
        routerLinkActive="active" *ngIf="UserType == '3' && CompanyName == 'Alankit'">
          <img alt="N/A" src="../../../assets/images/Sidebar/NCD.svg" class="svg" width="24px" />
          <a class="nav-link">IPO</a>
        </li>

        <li class="nav-item text-center" routerLink="/ipo/dashboardmain" routerLinkActive="active" *ngIf="UserType != '3' && CompanyName == 'Alankit'">
          <img alt="N/A" src="../../../assets/images/Sidebar/NCD.svg" class="svg" width="24px" />
          <a class="nav-link"><span>IPO <br> Dashboard</span></a>
        </li>

        <li class="nav-item text-center" routerLink="/ipo/AddClient" routerLinkActive="active" *ngIf="UserType != '3' && CompanyName == 'Alankit'">
          <img alt="N/A" src="../../../assets/images/Sidebar/NCD.svg" class="svg" width="24px" />
          <a class="nav-link"><span>IPO <br> Add Client</span></a>
        </li>

        <li class="nav-item text-center" routerLink="/ipo/client-bulk-upload" routerLinkActive="active" *ngIf="UserType != '3' && CompanyName == 'Alankit'">
          <img alt="N/A" src="../../../assets/images/Sidebar/NCD.svg" class="svg" width="24px" />
          <a class="nav-link"><span>IPO Client Bulk Upload</span></a>
        </li>

        <li class="nav-item text-center" routerLink="/referral-report" routerLinkActive="active" *ngIf="UserType == '1' && RoleId == '1'">
          <img alt="N/A" src="../../../assets/images/Sidebar/Subbroker.svg" class="svg" width="24px" />
          <a class="nav-link"><span>Approved SubBrokers</span></a>
        </li>

        <li class="nav-item text-center" routerLink="/referral-pending" routerLinkActive="active" *ngIf="UserType == '1'  && RoleId == '1'">
          <img alt="N/A" src="../../../assets/images/Sidebar/Subbroker.svg" class="svg" width="24px" />
          <a class="nav-link"><span>Pending SubBrokers</span></a>
        </li>

        <li class="nav-item text-center" routerLink="/rm-map" routerLinkActive="active" *ngIf="UserType == '1'  && RoleId == '1'">
          <img alt="N/A" src="../../../assets/images/Sidebar/Subbroker.svg" class="svg" width="24px" />
          <a class="nav-link"><span>Map <br> RM</span></a>
        </li>

      <li class="nav-item" routerLink="/ipo/index-ncd"
      routerLinkActive="active" *ngIf="UserType == '3' && CompanyName == 'Alankit'">
        <img alt="N/A" src="../../../assets/images/Sidebar/NCD.svg" class="svg" width="24px" />
        <a class="nav-link">Apply NCD</a>
      </li>
      
      </div>
      <div class="MenuDiv d-flex justify-content-center align-items-center">
        <!-- <span class="MenuTitle">Menu</span> -->
        <span class="CloseMenu" (click)="CloseMenu()"><img src="../../../assets/images/Close-white.svg" alt=""
            width="10px"></span>
      </div>
    </ul>
    <ul id="OthersMenuUl2" class="BoxShadow OthersMenuUl" style="display:none">
      <div class="MenuSection">
        <li class="nav-item" (click)="GoToProfile()">
          <img alt="N/A" src="../../../assets/images/Sidebar/Profile.svg" class="svg" width="24px" />
          <a class="nav-link">My Profile</a>
        </li>
        <li class="nav-item" routerLink="/reports-new/dashboard" routerLinkActive='active'>
          <img alt="N/A" src="../../../assets/images/JM/Reports.svg" class="svg" width="24px" />
          <a class="nav-link">Reports</a>
        </li>
        <!-- <li class="nav-item" routerLink="/mf-dashboard" routerLinkActive='active' *ngIf="UserType == '1'">
          <img src="../../../assets/images/JM/Home_new.svg" class="svg" width="24px" />
          <a class="nav-link">Dashboard IFD </a>
        </li>
        <li class="nav-item" routerLink="/client-dashboard" routerLinkActive='active' *ngIf="UserType != '3'">
          <img src="../../../assets/images/JM/Home_new.svg" class="svg" width="24px" />
          <a class="nav-link">Dashboard Client</a>
        </li> -->
        <!-- <li class="nav-item" routerLink="/clients" routerLinkActive='active' *ngIf="UserType != '3'">
          <img src="../../../assets/images/Sidebar/Clients.svg" class="svg" width="24px" />
          <a class="nav-link">Clients</a>
        </li> -->
        <li class="nav-item" routerLink="/referral-report" routerLinkActive='active' *ngIf="UserType == '1' && RoleId == '6'">
          <!--*ngIf="UserType == '1'"-->
          <img src="../../../assets/images/Sidebar/Subbroker.svg" class="svg" width="24px" />
          <a class="nav-link">Subbroker</a>
        </li>
        <!-- <li class="nav-item" routerLink="/campaign-management" routerLinkActive='active' *ngIf="UserType == '1'">
          <img src="../../../assets/images/Sidebar/Campaign_Management.svg" class="svg" width="24px" />
          <a class="nav-link">Campaign Management</a>
        </li> -->
        <li class="nav-item" (click)="LogOut()" *ngIf="isSSOLogin">
          <img alt="N/A" src="../../../assets/images/Icon_Logout.svg" class="svg" width="24px" />
          <a class="nav-link LogoutClr">Logout</a>
        </li>
      </div>
      <div class="MenuDiv Posi d-flex justify-content-center align-items-center">
        <!-- <span class="MenuTitle">Menu</span> -->
        <span class="CloseMenu" (click)="CloseMenu()"><img src="../../../assets/images/Close-white.svg" alt=""
            width="10px"></span>
      </div>
    </ul>
    <div *ngIf="!IsMobile && UserType == '3'">
      <ul [class.collapsed]="IsCollapseSideMenu" class="Sidemenu">
        <li class="UserDetails">
          <a class="Custom_a" [ngClass]="IsCollapseSideMenu ? 'Li_a_Padding' : ''" (click)="GoToProfile()">
            <div *ngIf="IsCollapseSideMenu" class="CollapseUser"><img src="../../../assets/images/UserProfile_Placeholder.svg"
                class="UserImgDefault" /></div>
            <div *ngIf="!IsCollapseSideMenu" class="d-flex justify-content-start align-items-center BorderBott">
              <img src="../../../assets/images/UserProfile_Placeholder.svg" class="UserImg">
              <span class="UserName">{{UserName}}</span>
              <!--{{UserName}} Shanti Subramaniam-->
            </div>
          </a>
        </li>
        <li class="" routerLink="/client-dashboard" routerLinkActive='active'>
          <a class="d-flex align-items-center">
            <div *ngIf="IsCollapseSideMenu" >
              <img class="svg" src="../../../assets/images/Sidebar/Home_new.svg" />
            </div>
            <div *ngIf="!IsCollapseSideMenu">
              <img class="svg" src="../../../assets/images/Sidebar/Home_new.svg" />
              <span *ngIf="!IsCollapseSideMenu" class="">Home</span>
            </div>  
          </a>
        </li>
        <li class="" routerLink="/transaction-new/lumpsum" routerLinkActive='active'>
          <a class="d-flex align-items-center">
            <div *ngIf="IsCollapseSideMenu">
              <img src="../../../assets/images/Sidebar/Online_Transaction.svg" class="svg" />
            </div>
            <div *ngIf="!IsCollapseSideMenu">
              <img src="../../../assets/images/Sidebar/Online_Transaction.svg" class="svg" />
              <span *ngIf="!IsCollapseSideMenu" class="">Online Transaction</span>
            </div>
          </a>
        </li>
        <li *ngIf="CompanyName != 'ABML_Money'" class="" routerLink="/goal-based-investing/dashboard" routerLinkActive='active'>
          <a class="d-flex align-items-center">
            <div *ngIf="IsCollapseSideMenu">
              <img src="../../../assets/images/Sidebar/Goal_Based_Investing.svg" class="svg" />
            </div>
            <div *ngIf="this.CompanyName=='NSDL'">
              <div *ngIf="!IsCollapseSideMenu" class="d-flex align-items-center">
                <img src="../../../assets/images/Sidebar/Goal_Based_Investing.svg" class="svg" />
                <span *ngIf="!IsCollapseSideMenu" class="">Calculators</span>
              </div>
            </div>
            <div *ngIf="!(this.CompanyName=='NSDL')">
              <div *ngIf="!IsCollapseSideMenu" class="d-flex align-items-center">
                <img src="../../../assets/images/Sidebar/Goal_Based_Investing.svg" class="svg" />
                <span *ngIf="!IsCollapseSideMenu" class="">Goal Based Investing</span>
              </div>
            </div>
            
          </a>
        </li>

        <li *ngIf="CompanyName == 'Alankit'" class="nav-item">
          <a class="nav-link nav-link_Arrow collapsed text-truncate" href="#fd" data-toggle="collapse"
            data-target="#fd">
            <img src="../../../assets/images/Sidebar/FD.svg" class="svg" />
            <span *ngIf="!IsCollapseSideMenu" class="d-none d-sm-inline Left_Menu">FD</span>
          </a>
          <div *ngIf="!IsCollapseSideMenu" class="collapse Trans" id="fd" aria-expanded="false">
            <ul class="flex-column pl-5 nav">
              <li class="nav-item"><a class="nav-link DropMenu_1" routerLink="/fd/fd-landing"
                  routerLinkActive="active"><span>Online FD</span></a></li>
              <li class="nav-item"><a class="nav-link DropMenu" routerLink="/fd/fd-orderbook"
                  routerLinkActive="active"><span>Mahindra FD Order Book</span></a></li>
              <!-- <li class="nav-item"><a class="nav-link DropMenu" routerLink="/fd/bajajfd-orderbook"
                  routerLinkActive="active"><span>Bajaj FD Order Book</span></a></li> -->
              <li class="nav-item"><a class="nav-link DropMenu" routerLink="/fd/shriram-fd-orderbook"
                  routerLinkActive="active"><span>Shriram FD Order Book</span></a></li>
            </ul>
          </div>
        </li>

        <li *ngIf="CompanyName == 'Alankit'" class="nav-item">
          <a class="nav-link nav-link_Arrow collapsed text-truncate" href="#sgb" data-toggle="collapse"
            data-target="#sgb">
            <img src="../../../assets/images/Sidebar/SGB.svg" />
            <span *ngIf="!IsCollapseSideMenu" class="d-none d-sm-inline Left_Menu">SGB</span>
          </a>
          <div *ngIf="!IsCollapseSideMenu" class="collapse Trans" id="sgb" aria-expanded="false">
            <ul class="flex-column pl-5 nav">
              <li class="nav-item"><a class="nav-link DropMenu_1" routerLink="/SGB/SGBInvest"
                  routerLinkActive="active"><span>Apply Now</span></a></li>
              <li class="nav-item"><a class="nav-link DropMenu" routerLink="/SGB/SGBOrderBook"
                  routerLinkActive="active"><span>Order Book</span></a></li>
            </ul>
          </div>
        </li>

        <li *ngIf="CompanyName == 'Alankit'" class="nav-item">
          <a class="nav-link nav-link_Arrow collapsed text-truncate" href="#ipo" data-toggle="collapse"
            data-target="#ipo">
            <img src="../../../assets/images/Sidebar/NCD.svg" />
            <span *ngIf="!IsCollapseSideMenu" class="d-none d-sm-inline Left_Menu">IPO/NCD</span>
          </a>
          <div *ngIf="!IsCollapseSideMenu" class="collapse Trans" id="ipo" aria-expanded="false">
            <ul class="flex-column pl-5 nav">
              <li class="nav-item" *ngIf="UserType == '3'"><a class="nav-link DropMenu" routerLink="/ipo/index"
                  routerLinkActive="active"><span>Apply/Track IPO</span></a></li>
              <li class="nav-item" *ngIf="UserType == '3'"><a class="nav-link DropMenu" routerLink="/ipo/index-ncd"
                  routerLinkActive="active"><span>Apply NCD </span></a></li>
            </ul>
          </div>
        </li>
        <li class="" routerLink="/explore-funds-new" routerLinkActive='active'>
          <a class="d-flex align-items-center">
            <div *ngIf="IsCollapseSideMenu">
              <img src="../../../assets/images/Sidebar/Explore_Funds.svg" class="svg" />
            </div>
            <div *ngIf="!IsCollapseSideMenu">
              <img src="../../../assets/images/Sidebar/Explore_Funds.svg" class="svg" />
              <span *ngIf="!IsCollapseSideMenu" class="">Explore Funds</span>
            </div>
          </a>
        </li>
        <li class="" routerLink="/reports-new/dashboard" routerLinkActive='active'>
          <a class="d-flex align-items-center">
            <div *ngIf="IsCollapseSideMenu">
              <img src="../../../assets/images/Sidebar/Reports.svg" class="svg"/>
            </div>
            <div *ngIf="!IsCollapseSideMenu">
              <img src="../../../assets/images/Sidebar/Reports.svg" class="svg"/>
              <span *ngIf="!IsCollapseSideMenu" class="">Reports</span>
            </div>
          </a>
        </li>

        <li class=" " (click)="LogOut()"><a class="d-flex align-items-center"><img
              src="../../../assets/images/Icon_Logout.svg" /><span *ngIf="!IsCollapseSideMenu"
              class="Left_Menu LogoutClr">Logout</span></a></li>
        <!--PlacedBtm-->
      </ul>
    </div>

    <div *ngIf="!IsMobile && UserType == '2'">
      <ul [class.collapsed]="IsCollapseSideMenu" class="Sidemenu">
        <li class="UserDetails">
          <a class="Custom_a" [ngClass]="IsCollapseSideMenu ? 'Li_a_Padding' : ''" (click)="GoToProfile()">
            <div *ngIf="IsCollapseSideMenu" class="CollapseUser"><img src="../../../assets/images/UserProfile_Placeholder.svg"
                class="UserImgDefault" /></div>
            <div *ngIf="!IsCollapseSideMenu" class="d-flex justify-content-start align-items-center BorderBott">
              <img src="../../../assets/images/UserProfile_Placeholder.svg" class="UserImg">
              <span class="UserName">{{UserName}}</span>
              <!--{{UserName}} Shanti Subramaniam-->
            </div>
          </a>
        </li>
        <li class="" routerLink="/sb-dashboard" routerLinkActive='active'>
          <a class="d-flex align-items-center">
            <div *ngIf="IsCollapseSideMenu" >
              <img class="svg" src="../../../assets/images/Sidebar/Home_new.svg" />
            </div>
            <img class="svg" src="../../../assets/images/Sidebar/Home_new.svg" />
            <span *ngIf="!IsCollapseSideMenu" class="">Home</span>
          </a>
        </li>
        <li class="" routerLink="/transaction-new/lumpsum" routerLinkActive='active'>
          <a class="d-flex align-items-center"> 
            <div *ngIf="IsCollapseSideMenu" >
            <img class="svg" src="../../../assets/images/Sidebar/Online_Transaction.svg" />
          </div>
            <img src="../../../assets/images/Sidebar/Online_Transaction.svg" class="svg" />
            <span *ngIf="!IsCollapseSideMenu" class="">Online Transaction</span>
          </a>
        </li>
        <li class="" routerLink="/clients" routerLinkActive='active'>
          <a class="d-flex align-items-center">
            <div *ngIf="IsCollapseSideMenu" >
              <img class="svg" src="../../../assets/images/Sidebar/Clients.svg" />
            </div>
            <img src="../../../assets/images/Sidebar/Clients.svg" class="svg" width="24px" />
            <span *ngIf="!IsCollapseSideMenu" class="">Clients</span>
          </a>
        </li>
        <li *ngIf="CompanyName != 'ABML_Money'" class="" routerLink="/goal-based-investing/dashboard" routerLinkActive='active'>
          <a class="d-flex align-items-center">
            <div *ngIf="IsCollapseSideMenu" >
              <img class="svg" src="../../../assets/images/Sidebar/Goal_Based_Investing.svg" />
            </div>
                <img src="../../../assets/images/Sidebar/Goal_Based_Investing.svg" class="svg" />
                <span *ngIf="!IsCollapseSideMenu" class="">Calculators</span>
            <!-- <div *ngIf="!(this.CompanyName=='NSDL')">
              <div *ngIf="!IsCollapseSideMenu" class="d-flex align-items-center">
                <img src="../../../assets/images/Sidebar/Goal_Based_Investing.svg" class="svg" />
                <span *ngIf="!IsCollapseSideMenu" class="">Goal Based Investing</span>
              </div>
            </div> -->
          </a>
        </li>

        <li *ngIf="CompanyName == 'Alankit'" class="nav-item">
          <a class="nav-link nav-link_Arrow collapsed text-truncate" href="#ipo" data-toggle="collapse"
            data-target="#ipo">
            <img src="../../../assets/images/Sidebar/NCD.svg" />
            <span *ngIf="!IsCollapseSideMenu" class="d-none d-sm-inline Left_Menu">IPO/NCD</span>
          </a>
          <div *ngIf="!IsCollapseSideMenu" class="collapse Trans" id="ipo" aria-expanded="false">
            <ul class="flex-column pl-5 nav">
              <li class="nav-item" ><a class="nav-link DropMenu" routerLink="/ipo/dashboardmain"
                  routerLinkActive="active"><span>Dashboard</span></a></li>
              <li class="nav-item"><a class="nav-link DropMenu" routerLink="/ipo/AddClient"
                  routerLinkActive="active"><span>Add Client</span></a></li>
                  <li class="nav-item"><a class="nav-link DropMenu" routerLink="/ipo/client-bulk-upload"
                    routerLinkActive="active"><span>Client Bulk Upload</span></a></li>
            </ul>
          </div>
        </li>

        <li *ngIf="CompanyName == 'Alankit'" class="nav-item">
          <a class="nav-link nav-link_Arrow collapsed text-truncate" href="#fd" data-toggle="collapse"
            data-target="#fd">
            <img src="../../../assets/images/Sidebar/FD.svg" class="svg" />
            <span *ngIf="!IsCollapseSideMenu" class="d-none d-sm-inline Left_Menu">FD</span>
          </a>
          <div *ngIf="!IsCollapseSideMenu" class="collapse Trans" id="fd" aria-expanded="false">
            <ul class="flex-column pl-5 nav">
              <li class="nav-item"><a class="nav-link DropMenu_1" routerLink="/fd/fd-landing"
                  routerLinkActive="active"><span>Online FD</span></a></li>
              <li class="nav-item"><a class="nav-link DropMenu" routerLink="/fd/fd-orderbook"
                  routerLinkActive="active"><span>Mahindra FD Order Book</span></a></li>
              <!-- <li class="nav-item"><a class="nav-link DropMenu" routerLink="/fd/bajajfd-orderbook"
                  routerLinkActive="active"><span>Bajaj FD Order Book</span></a></li> -->
              <li class="nav-item"><a class="nav-link DropMenu" routerLink="/fd/shriram-fd-orderbook"
                  routerLinkActive="active"><span>Shriram FD Order Book</span></a></li>
            </ul>
          </div>
        </li>

        <li *ngIf="CompanyName == 'Alankit'" class="nav-item">
          <a class="nav-link nav-link_Arrow collapsed text-truncate" href="#sgb" data-toggle="collapse"
            data-target="#sgb">
            <img src="../../../assets/images/Sidebar/SGB.svg" />
            <span *ngIf="!IsCollapseSideMenu" class="d-none d-sm-inline Left_Menu">SGB</span>
          </a>
          <div *ngIf="!IsCollapseSideMenu" class="collapse Trans" id="sgb" aria-expanded="false">
            <ul class="flex-column pl-5 nav">
              <li class="nav-item"><a class="nav-link DropMenu_1" routerLink="/SGB/SGBInvest"
                  routerLinkActive="active"><span>Apply Now</span></a></li>
              <li class="nav-item"><a class="nav-link DropMenu" routerLink="/SGB/SGBOrderBook"
                  routerLinkActive="active"><span>Order Book</span></a></li>
            </ul>
          </div>
        </li>

       
        <li class="" routerLink="/explore-funds-new" routerLinkActive='active'>
          <a class="d-flex align-items-center">
            <div *ngIf="IsCollapseSideMenu" >
              <img class="svg" src="../../../assets/images/Sidebar/Explore_Funds.svg" />
            </div>
            <img src="../../../assets/images/Sidebar/Explore_Funds.svg" class="svg" />
            <span *ngIf="!IsCollapseSideMenu" class="">Explore Funds</span>
          </a>
        </li>
        <li class="" routerLink="/reports-new/dashboard" routerLinkActive='active'>
          <a class="d-flex align-items-center">
            <div *ngIf="IsCollapseSideMenu" >
              <img class="svg" src="../../../assets/images/Sidebar/Reports.svg" />
            </div>
            <img src="../../../assets/images/Sidebar/Reports.svg" class="svg"/>
            <span *ngIf="!IsCollapseSideMenu" class="">Reports</span>
          </a>
        </li>

        <li class=" " (click)="LogOut()"><a class="d-flex align-items-center"><img
              src="../../../assets/images/Icon_Logout.svg" /><span *ngIf="!IsCollapseSideMenu"
              class="Left_Menu LogoutClr">Logout</span></a></li>
        <!--PlacedBtm-->
      </ul>
    </div>

    <!-- For Subbroker and Employee : Dynamic menu binding : Added by Saddam -->
    <div *ngIf="!IsMobile && UserType != '3' && UserType != '2'">
      <div id="header" [ngClass]="IsShow">
        <ul [class.collapsed]="IsCollapseSideMenu" class="Sidemenu">
          <li class="UserDetails">
            <a class="Custom_a" [ngClass]="IsCollapseSideMenu ? 'Li_a_Padding' : ''" (click)="GoToProfile()">
              <div *ngIf="IsCollapseSideMenu" class="CollapseUser"><img src="../../../assets/images/UserProfile_Placeholder.svg"
                  class="UserImgDefault" /></div>
              <div *ngIf="!IsCollapseSideMenu" class="d-flex justify-content-start align-items-center BorderBott">
                <img src="../../../assets/images/UserProfile_Placeholder.svg" class="UserImg">
                <span class="UserName">{{UserName}}</span>
              </div>
            </a>
          </li>
          <li *ngFor="let item of MenuList; let i = index" class="nav-item" routerLinkActive='active'>
            <div *ngIf="item.MainMenuURL != ''">
              <a class="d-flex align-items-center" [routerLink]="item.MainMenuURL"><img
                  src="{{GetImgSource(item.MainMenuName)}}" class="svg"/><span *ngIf="!IsCollapseSideMenu"
                  class="d-flex align-items-center">{{item.MainMenuName}}</span></a>
            </div>
            <div *ngIf="item.MainMenuURL == ''">
              <a class="nav-link nav-link_Arrow collapsed text-truncate" href='#{{ReplaceChar(item.MainMenuName)}}'
                data-toggle="collapse" data-target="#{{ReplaceChar(item.MainMenuName)}}">
                <img src="{{GetImgSource(item.MainMenuName)}}"/><span class="MainMenu"
                  *ngIf="!IsCollapseSideMenu">{{item.MainMenuName}}</span>
              </a>
              <div class="collapse Trans" id="{{ReplaceChar(item.MainMenuName)}}" aria-expanded="false"
                *ngIf="!IsCollapseSideMenu">
                <ul class="flex-column pl-5 nav">
                  <li class="nav-item" *ngFor="let submenu of item.data">
                    <a class="nav-link DropMenu MainSubMenu" [routerLink]="submenu.SubMenuURL">{{submenu.SubMenuName}}</a>
                  </li>
                </ul>
              </div>
            </div>
          </li>
          <li class=" " (click)="LogOut()"><a class="d-flex align-items-center"><img
                src="../../../assets/images/Icon_Logout.svg" /><span *ngIf="!IsCollapseSideMenu"
                class="Left_Menu LogoutClr">Logout</span></a></li>
        </ul>
      </div>
    </div>


    <div *ngIf="IsMobile" class="UserDetails_Mob">
      <div class="d-flex justify-content-between align-items-center">
        <div class="d-flex justify-content-between align-items-center">
          <img src="../../../assets/images/UserProfile_Placeholder.svg" class="UserImg">
          <div style="line-height: 18px;">
            <div class="Hello">Hello</div>
            <div class="UserName_Mob">{{UserName}}</div>
          </div>
        </div>
        <!-- <div>
          <img src="../../../assets/images/JM/IFD_Logo.png" alt="Logo" height="38px;" class="logo">
        </div> -->
      </div>
    </div>
  </div>
</div>

<div class="modal" id="QRCode" appDisabledRightClick>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content AlertModalContentCls">
      <div class="modal-header AlertModalHeader">QR Code</div>
      <div class="modal-body AlertModalBody">

        <div><img class="SuccessPage" class="SuccessPage" [src]="QRCodeImage"></div>
        <div class="">{{QRCodeURL}} </div>

      </div>
      <div class="modal-footer AlertModalFooter">
        <button type="button" class="CursorPointer" (click)="CopyURL()">
          <img src="../../../assets/images/copy.svg" style="width: 25px;height: 25px;" />
          <span>{{CopyTest}}</span>

        </button>
        <button type="button" (click)="DownloadQRCode()" class="CursorPointer">Download QR Code</button>
        <button type="button" class="CursorPointer" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>