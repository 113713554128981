export const environment = {
  production: true,
  CommonBaseHref:'',
  CompayName: "NSDL",
  CompanyId:1,
  environmentDigio:"sandbox",
  ServerUrl: 'https://mfready.nsdlbank.com/wrapperapi/' // ABML URL
};



