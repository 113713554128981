<nav class="navbar fixed-top p-0 NavbarBg" role="navigation" [style.min-height]="NavHeight">
    <div class="container-fluid" [ngClass]="IsMobile ? 'p-0':''">
        <div class="navbar-header">
            <a class="navbar-brand LogoAnchorClass logo1" [ngClass]="CompanyName == 'ABML_Money' ? 'MarginLR':''">
                <img class="logo_img" [src]='logo' [style.height]="logoHeight">
            </a>
        </div>
    </div>
</nav>
<div *ngIf="CompanyName == 'ABML_Money'" class="Second_Band fixed-top" [ngClass]="isHidden ? 'hidden':''">Aditya Birla Money Limited</div>
