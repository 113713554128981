import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ProfileService } from 'src/app/services/profile.service';
import { EncrdecrService } from '../../Auth/encrdecr.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { OtpComponent } from 'src/app/shared/otp/otp.component';
declare var $:any;
@Component({
  selector: 'app-fd-payment',
  templateUrl: './fd-payment.component.html',
  styleUrls: ['./fd-payment.component.scss']
})
export class FdPaymentComponent implements OnInit {
  diffHrs: number;
  dataString: string;
  client_ID: any;
  OrderId : any = '';
  ApplicationNo : any = '';
  public bsModelRef: BsModalRef;
  constructor(private router: Router, private route: ActivatedRoute,private EncrdecrService: EncrdecrService,
    private clientProfileService: ProfileService,private bsModelService: BsModalService) { }

  ngOnInit() {

    debugger
    this.route.queryParams.subscribe(params => {
      this.dataString = this.EncrdecrService.getDecr(
      params.d.toString().replace(/\s/g, '+'));
      const validDate = this.EncrdecrService.getDecr(
      params.v.toString().replace(/\s/g, '+'));
      this.client_ID = this.EncrdecrService.getDecr(
      params.uni.toString().replace(/\s/g, '+'));
      sessionStorage.setItem('N1QJHdOkO7Hz8QC',this.EncrdecrService.setEncr(this.client_ID));
      sessionStorage.setItem('MjTFLjHtTGH86dfjh','FDVerification');
      let endDate = new Date();
      let purchaseDate = new Date(validDate);
      let diffMs = (endDate.valueOf() - purchaseDate.valueOf()); // milliseconds
      let diffDays = Math.floor(diffMs / 86400000); // days
      this.diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
      let diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
      const request = {
        "VERIFICATION_ID" : this.client_ID,
        "Trans_Type": 'FDVerification',
        "Flag": "G"
      };
      var json = JSON.stringify(request);
      this.clientProfileService.SendOTP(json).subscribe(response => {
        //let data = JSON.parse(this.EncrdecrService.getDecr(response))
      });


      this.bsModelRef = this.bsModelService.show(OtpComponent, {
        class: "modal-dialog-centered modal-lg modal_otp",
        ignoreBackdropClick: true,
      });
      this.bsModelRef.content.onClose = new Subject<boolean>();
      this.bsModelRef.content.action = new Subject<boolean>();
      this.bsModelRef.content.onClose.subscribe((res) => {
        //res = true; //Added for testing
        if (res == true) {
          sessionStorage.removeItem('MjTFLjHtTGH86dfjh');
          if(this.diffHrs > 24){
            this.OpenProfileModel(
              "The payment link has been expired."
            );
          }
          else{
            //Updating Client IP Address
            console.log("Client Payment URL data :",this.dataString)
            let Arr1 = this.dataString.split(" ");
            console.log("Array split by space :",Arr1)

            for(let i =0 ; i<Arr1.length; i++){
              if(Arr1[i].includes("value")){
                let Arr2 = Arr1[i].split("=")
                let Arr3 = Arr2[1].split("|")
                this.OrderId = Arr3[3]
                this.ApplicationNo = Arr3[1]
                console.log("OrderID : ",this.OrderId)
                console.log("ApplicationNo : ",this.ApplicationNo)
                break;
              }
            }

debugger
            const request = {
              "ClientBasicInfoId" : this.OrderId,
              "UCCCode": this.ApplicationNo,
            };
            var json = JSON.stringify(request);
            this.clientProfileService.UpdateClientIPForFD(json).subscribe(response => {
              let data = JSON.parse(this.EncrdecrService.getDecr(response))
               this.OpenInternetBankingPage(this.dataString)
            },
            error => {
              this.OpenInternetBankingPage(this.dataString)
            },
            );

          }
        }
      });
      this.bsModelRef.content.action.subscribe((res) => {
        if (res == true) {
          this.ResendOTP();
        }
      });

    });

  }
  OpenInternetBankingPage(html: string) {

    //var newWindow = window.open();
    //newWindow.document.write(html);
    document.open()
      document.write(html);
    document.close()
  }
  OpenProfileModel(msg: String) {
    $("#fdAlertMode").modal({
      backdrop: "static",
      keyboard: false,
    });
    $("#fdAlterMsg").text(msg);
  }
  onclickclose(){
    window.close()
  }
  ResendOTP(){
    const request = {
      "VERIFICATION_ID" : this.client_ID,
      "Trans_Type": 'FDVerification',
      "Flag": "G"
    };
    var json = JSON.stringify(request);
    this.clientProfileService.SendOTP(json).subscribe(response => {
      //let data = JSON.parse(this.EncrdecrService.getDecr(response))
      if(response.Message != 'Success'){
        this.CallAlertModal(response.Message)
      }
    });
  }
  CallAlertModal(msg: any) {
    $("#fdAlertMode").modal({
      backdrop: 'static',
      keyboard: false
    });
    $('#fdAlterMsg').text(msg);
  }
}
