
import { CpAccessGuard } from './Auth/cp-access.guard';
import { EmpAccessGuard } from './Auth/emp-access.guard';

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './Auth/auth.guard';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { RoleAccessGuard } from './Auth/role-access.guard';
import { FdPaymentComponent } from './FD/fd-payment/fd-payment.component';
import { ProfilePreviewModelComponent } from './shared/profile-preview-model/profile-preview-model.component';
import { ThankYouFDComponent } from './thank-you-fd/thank-you-fd.component';

//-------      

const routes: Routes = [
  { path: '', redirectTo: '/login-corp', pathMatch: 'full' },
  { path: 'login-corp', loadChildren: () => import('./login-nse/login-nse.module').then(m => m.LoginNseModule) },
  { path: 'ipo', loadChildren: () => import('./IPO/ipo.module').then(m => m.IpoModule) }, //for IPO
  { path: 'employee-login', loadChildren: () => import('./employee-login/employee-login.module').then(m => m.EmployeeLoginModule) },
  { path: 'access-denied', loadChildren: () => import('./access-denied/access-denied.module').then(m => m.AccessDeniedModule) },
  { path: 'clients', loadChildren: () => import('./clients/clients.module').then(m => m.ClientsModule), canActivate: [AuthGuard, RoleAccessGuard] },
  { path: 'cp-forgot-password', loadChildren: () => import('./cp-forgot-password/cp-forgot-password.module').then(m => m.CpForgotPasswordModule) },
  { path: 'cp-login', loadChildren: () => import('./cp-login/cp-login.module').then(m => m.CpLoginModule) },
  { path: 'cp-onboarding', loadChildren: () => import('./cp-onboarding/cp-onboarding.module').then(m => m.CpOnboardingModule) },
  { path: 'employee-dashboard', loadChildren: () => import('./employee-dashboard/employee-dashboard.module').then(m => m.EmployeeDashboardModule), canActivate: [EmpAccessGuard] },
  { path: 'factsheet', loadChildren: () => import('./factsheet/factsheet.module').then(m => m.FactsheetModule), canActivate: [AuthGuard] },
  { path: 'forgot-password', loadChildren: () => import('./forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule) },
  { path: 'generate-password', loadChildren: () => import('./generate-one-time-password/generate-one-time-password.module').then(m => m.GenerateOneTimePasswordModule) },
  { path: 'client-onboarding', loadChildren: () => import('./onboarding/client-onboarding/client-onboarding.module').then(m => m.ClientOnboardingModule) },
  { path: 'distributor-onboarding', loadChildren: () => import('./onboarding/distributor-onboarding/distributor-onboarding.module').then(m => m.DistributorOnboardingModule) },
  { path: 'client-preOnboading', loadChildren: () => import('./onboarding/client-pre-onboarding/client-pre-onboarding.module').then(m => m.ClientPreOnboardingModule) },
  { path: 'SGB', loadChildren: () => import('./SGB/sgb.module').then(m => m.SGBModule), canActivate: [AuthGuard] },
  { path: 'fd', loadChildren: () => import('./FD/fd.module').then(m => m.FdModule), canActivate: [AuthGuard] },
  { path: 'iin-upload', loadChildren: () => import('./iin-upload/iin-upload.module').then(m => m.IinUploadModule), canActivate: [AuthGuard] },
  { path: 'reports', loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule), canActivate: [AuthGuard] },
  { path: 'client-nse', loadChildren: () => import('./profile/client/client.module').then(m => m.ClientModule), canActivate: [AuthGuard] },
  { path: 'client-profile', loadChildren: () => import('./profile/client-profile/client-profile.module').then(m => m.ClientProfileModule), canActivate: [AuthGuard] },
  { path: 'distributor-profile', loadChildren: () => import('./profile/profile/profile.module').then(m => m.ProfileModule), canActivate: [AuthGuard] },
  { path: 'mf-dashboard', loadChildren: () => import('./mf-dashboard/mf-dashboard.module').then(m => m.MfDashboardModule), canActivate: [AuthGuard] },
  { path: 'mb-logout', loadChildren: () => import('./mobile-logout/mobile-logout.module').then(m => m.MobileLogoutModule) },
  { path: 'subbroker', loadChildren: () => import('./subbroker/subbroker.module').then(m => m.SubbrokerModule), canActivate: [AuthGuard, RoleAccessGuard] },
  { path: 'sipcancellation', loadChildren: () => import('./sipcancellation/sipcancellation.module').then(m => m.SipcancellationModule) },
  { path: 'transaction-entry', loadChildren: () => import('./transaction-entry/transaction-entry.module').then(m => m.TransactionEntryModule) },
  { path: 'fd-payment', component: FdPaymentComponent },
  { path: 'profilepreview', component: ProfilePreviewModelComponent },
  { path: 'sb-emp', loadChildren: () => import('./sb-emp/sb-emp.module').then(m => m.SbEmpModule), canActivate: [AuthGuard] },
  { path: 'deal-entry', loadChildren: () => import('./deal-entry/deal-entry.module').then(m => m.DealEntryModule), canActivate: [AuthGuard, CpAccessGuard] },
  { path: 'Client-Profile-Preview', loadChildren: () => import('./client-profile-preview/client-profile-preview.module').then(m => m.ClientProfilePreviewModule), canActivate: [AuthGuard] },
  { path: 'mis-report', loadChildren: () => import('./reports/mis-report/mis-report.module').then(m => m.MisReportModule) },
  { path: 'transaction-new', loadChildren: () => import('./transaction-new/transaction-new.module').then(m => m.TransactionNewModule) },
  { path: 'explore-funds-new', loadChildren: () => import('./explore-funds-new/explore-funds-new.module').then(m => m.ExploreFundsNewModule), canActivate: [AuthGuard] },
  { path: 'factsheet-new', loadChildren: () => import('./factsheet-new/factsheet-new.module').then(m => m.FactsheetNewModule), canActivate: [AuthGuard] },
  { path: 'clients-new', loadChildren: () => import('./clients-new/clients-new.module').then(m => m.ClientsNewModule), canActivate: [AuthGuard] },
  { path: 'referral-report', loadChildren: () => import('./JM/referral-report/referral-report.module').then(m => m.ReferralReportModule), canActivate: [AuthGuard] },
  { path: 'referral-pending', loadChildren: () => import('./JM/referral-pending/referral-pending.module').then(m => m.ReferralPendingModule), canActivate: [AuthGuard] },
  { path: 'referral-onboarding', loadChildren: () => import('./JM/referral-onboarding/referral-onboarding.module').then(m => m.ReferralOnboardingModule) },
  { path: 'onboarding-preview', loadChildren: () => import('./JM/onboarding-preview/onboarding-preview.module').then(m => m.OnboardingPreviewModule) },
  { path: 'campaign-management', loadChildren: () => import('./campaign-management/campaign-management.module').then(m => m.CampaignManagementModule), canActivate: [AuthGuard] },
  { path: 'pdfview', loadChildren: () => import('./pdfpopup/pdfpopup.module').then(m => m.PdfpopupModule) },
  { path: 'goal-based-investing', loadChildren: () => import('./goal-based-investing/goal-based-investing.module').then(m => m.GoalBasedInvestingModule), canActivate: [AuthGuard] },
  { path: 'dashboard-client', loadChildren: () => import('./dashboard-client/dashboard-client.module').then(m => m.DashboardClientModule), canActivate: [AuthGuard] },
  { path: 'dashboard-ifd', loadChildren: () => import('./dashboard-ifd/dashboard-ifd.module').then(m => m.DashboardIfdModule), canActivate: [AuthGuard] },
  { path: 'reports-new', loadChildren: () => import('./reports-new/reports-new.module').then(m => m.ReportsNewModule), canActivate: [AuthGuard] },
  { path: 'dashboard-employee', loadChildren: () => import('./dashboard-employee/dashboard-employee.module').then(m => m.DashboardEmployeeModule), canActivate: [AuthGuard] },
  { path: 'thank-you-fd', component: ThankYouFDComponent },
  { path: 'client-dashboard', loadChildren: () => import('./client-dashboard/client-dashboard.module').then(m => m.ClientDashboardModule), canActivate: [AuthGuard] },
  { path: 'sb-dashboard', loadChildren: () => import('./sb-dashboard/sb-dashboard.module').then(m => m.SbDashboardModule), canActivate: [AuthGuard] },
  { path: 'SingleSignOn', loadChildren: () => import('./single-sign-on/single-sign-on.module').then(m => m.SingleSignOnModule) },
  { path: 'NSDLSSOExpire', loadChildren: () => import('./nsdlssoexpire/nsdlssoexpire.module').then(m => m.NSDLSSOExpireModule) },
  { path: 'userotpverification', loadChildren: () => import('./verify/verify.module').then(m => m.VerifyModule) },
  { path: 'transact-summary', loadChildren: () => import('./transact-summary/transact-summary.module').then(m => m.TransactSummaryModule) },
  { path: '**', component: PageNotFoundComponent },


];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }
