
<div class="container mdlCls">
  <div class="modal-header p-1">
    <h4 class="modal-title Header" id="modal-basic-title">Add Nominee</h4>
  </div>
  <div class="main-div">
    <div id="">
      <form autocomplete="off" [formGroup]="clientDetailsForm" (ngSubmit)="onSubmitNomineeDetails()" id="clientDetailsForm">

        <div class="row">

          <div  class="col-md-12 col-sm-6 col-xs-12 InputBoxOuter ModelPadding">
            <div class="">
              <input type="text" id="NomineeName" formControlName="NomineeName" maxlength="50"
                class="form-control CommonTextBox TbxMarginBtm" placeholder="Nominee Name"
                [ngClass]="{ 'is-invalid': clientDetailsFormSubmitted && j.NomineeName.errors }" (keypress)="ValidatePattern($event)"/>
              <!-- <label for="NomineeName">Nominee Name</label> -->
              <div *ngIf="clientDetailsFormSubmitted && j.NomineeName.errors" class="invalid-feedback">
                <div *ngIf="j.NomineeName.errors.required">Nominee name name is required.</div>
                <div *ngIf="j.NomineeName.errors.pattern">Enter valid Nominee name containing characters and
                  spaces.</div>
              </div>
            </div>
          </div>
            <div
            class="col-md-12 col-sm-6 col-xs-12 InputBoxOuter ModelPadding">
            <div class="">
              <input type="text" id="NomineeDateofBirth" formControlName="NomineeDateofBirth"
                (blur)='IsNomineeMinor($event)'
                class="form-control CommonTextBox TbxMarginBtm clsCommonDatePicker clsDatePickerNomineeDOB"
                placeholder="Date of Birth of Nominee"
                [ngClass]="{ 'is-invalid': clientDetailsFormSubmitted && j.NomineeDateofBirth.errors }" readonly="readonly" />
              <!-- <label for="NomineeDateofBirth">Date of Birth of Nominee</label> -->
              <div *ngIf="clientDetailsFormSubmitted && j.NomineeDateofBirth.errors" class="invalid-feedback">
                <div *ngIf="j.NomineeDateofBirth.errors.required">Date of Birth is required.</div>
                <div *ngIf="j.NomineeDateofBirth.errors.pattern">Please enter valid date.</div>
              </div>
            </div>
          </div>
            <div
            class="col-md-12 col-sm-6 col-xs-12 InputBoxOuter ModelPadding">
            <div class="">
              <select formControlName="NomineeRelation"
                class="form-control CommonSelectBox TbxMarginBtm CustomSelectNomRel" placeholder="&nbsp;"
                [ngClass]="{ 'is-invalid': clientDetailsFormSubmitted && j.NomineeRelation.errors }">
                <option value="">Relationship With Nominee</option>
                <option *ngFor="let rel of RelationList" [value]="rel.ValueField">{{rel.TextField}}</option>
              </select>
              <!-- <label>Relationship With Nominee</label> -->
              <div *ngIf="clientDetailsFormSubmitted && j.NomineeRelation.errors" class="invalid-feedback">
                <div *ngIf="j.NomineeRelation.errors.required">Relationship with account holder is required.</div>
              </div>
            </div>
          </div>
          <div  class="col-md-12 col-sm-6 col-xs-12 InputBoxOuter ModelPadding">
              <div class="CheckAgree mt-2">
                <label class="Cbx_container">
                  <input type="checkbox" formControlName='Isnominateminor' name='Nominaeeshow' value="Nominaeeshow" (click)="IsNomineeMinorClick($event)"  id="iwishtonominee">
                  <span class="checkmark"></span>
                  <strong><span>Is Nominee Minor</span></strong>
              </label>
              </div>
            </div>
          <div [ngClass]="isGuardianNameshow? 'd-block':'d-none'" class="col-md-12 col-sm-6 col-xs-12 InputBoxOuter ModelPadding">
            <div class="">
              <input type="text" id="GuardianName" formControlName="GuardianName" maxlength="50"
                class="form-control CommonTextBox TbxMarginBtm" placeholder="Guardian Name"
                [ngClass]="{ 'is-invalid': clientDetailsFormSubmitted && j.GuardianName.errors }" (keypress)="ValidatePattern($event)" />
              <!-- <label for="GuardianName">Guardian Name</label> -->
              <div *ngIf="clientDetailsFormSubmitted && j.GuardianName.errors" class="invalid-feedback">
                <div *ngIf="j.GuardianName.errors.required">Guardian name is required.</div>
                <div *ngIf="j.GuardianName.errors.pattern">Enter valid guardian name containing characters and
                  spaces.</div>
              </div>
            </div>
          </div>

          <div [ngClass]="isGuardianRelationshow? 'd-block':'d-none'"
            class="col-md-12 col-sm-6 col-xs-12 InputBoxOuter ModelPadding">
            <div class="">
              <select formControlName="GuardianRelation"
                class="form-control CommonSelectBox TbxMarginBtm CustomSelectGuarRel" placeholder="&nbsp;"
                [ngClass]="{ 'is-invalid': clientDetailsFormSubmitted && j.GuardianRelation.errors }">
                <option *ngFor="let rel of RelationList" [value]="rel.ValueField">{{rel.TextField}}</option>
              </select>
              <!-- <label>Relationship With Guardian</label> -->
              <div *ngIf="clientDetailsFormSubmitted && j.GuardianRelation.errors" class="invalid-feedback">
                <div *ngIf="j.GuardianRelation.errors.required">Relationship with Guardian is required.</div>
              </div>
            </div>
          </div>
        </div>
        <button class="CommonSubmitButton ml-3 mt-2 mb-2" [disabled]="clientDetailsForm.invalid">Submit & Proceed</button>
       </form>
    </div>
  </div>
</div>

<div class="modal mdlCls" id="AlertModalprofilemodels" appDisabledRightClick data-backdrop='static'
  data-keyboard='false'>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content AlertModalContentCls">
      <div class="modal-header AlertModalHeader">Important Message</div>
      <div id="AlertModalprofilemodelsContentmodels" class="modal-body AlertModalBody"></div>
      <div class="modal-footer AlertModalFooter">
        <button type="button" data-dismiss="modal" class="CursorPointer">Okay</button>
      </div>
    </div>
  </div>
</div>

