import { RandomStringService } from './random-string.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { MainHeaderMenuService } from '../services/main-header-menu.service';
import { EncrdecrService } from '../Auth/encrdecr.service';
import { catchError } from 'rxjs/operators';
import { ConvertToJSONService } from './convert-to-json.service';
const headers = { headers: new HttpHeaders({ 'content-type': 'application/json' }) }
@Injectable({
  providedIn: 'root'
})
export class LoginService {
 
  AuthData : string;
  GlobalARN : string;
  ServerUrl = environment.ServerUrl;
  errorData: {};
  CompayName: string = environment.CompayName;
  constructor(private http: HttpClient, 
    private EncrdecrService : EncrdecrService,
    private randomString:RandomStringService,
    private MainHeaderMenuService:MainHeaderMenuService,
    private ConvertToJSON: ConvertToJSONService) { }

  PostLoginInfo(formData:any,UserType:number,IP:string = ''): Observable<any> {
    ////////
    let DataUrl = `${this.ServerUrl}/api/Authenticate/Authenticate`;
    let fd = JSON.parse(formData);
    if(UserType === 2){
      fd.Username = `ARN-${fd.Username}`
    }
    let un = this.EncrdecrService.setEncr(fd.Username);
    let pd = this.EncrdecrService.setEncr(fd.Password);
    let ut = UserType;
    let TOKEN = btoa(`${un}:${pd}:${ut}`);

    //alert(TOKEN);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Basic ${TOKEN}`
      })
    }
    return this.http.post<any>(DataUrl, '', httpOptions).pipe(
      catchError(this.handleError)
    );
    
  }
  Logout(TokenID:string): Observable<any> {
    let DataUrl = `${this.ServerUrl}/api/Logout/Logout`;
    const req = {
      "TOKEN_ID":TokenID.split('-')[0]
    }
    const body = JSON.stringify(req);
    this.MainHeaderMenuService.hide(); //Added by Rohan to hide main header
    return this.http.post<any>(DataUrl,body,headers).pipe();
  }

  SendOTP(body): Observable<any[]> {
    
     let url = `${this.ServerUrl}/api/OTP/Details`;
    //let url = `${this.ServerUrl}/api/SMSService/SendOTP`;
    return this.http.post<any[]>(url,body, headers).pipe();
  }

  JMOTPMobile(formData:any):Observable<any[]>{
    debugger
    if(this.CompayName == 'NSDL'){
      let url = `${this.ServerUrl}/api/SMSService/SendOTP`;
      const req = {
        data:this.EncrdecrService.setEncr(formData)
      }
      return this.http.post<any>(url, req, headers);
    }
    else{
      let url = `${this.ServerUrl}/api/CommonOTP/SendOTPSMSJM`;
      const req = {
        data:this.EncrdecrService.setEncr(formData)
      }
      return this.http.post<any>(url, req, headers);
    }
   
  }

  JMOTPEmail(formData:any):Observable<any[]>{
    
    let url = `${this.ServerUrl}/api/CommonOTP/SendEmailOTPJM`;
    const req = {
      data:this.EncrdecrService.setEncr(formData)
    }
    return this.http.post<any>(url, req, headers);
  }

  VerifyOTP(body): Observable<any[]> {
    let url = `${this.ServerUrl}/api/Forgotpass/VerifyOTP`;
    return this.http.post<any[]>(url,body, headers).pipe();
  }

  ChangePassword(formData): Observable<any[]> {
    ////////
    let url = `${this.ServerUrl}/api/Forgotpass/Details`;
    return this.http.post<any>(url, formData, headers).pipe();
  }

  fetchClientDetailsBasedOnMobileOrEmail(data: any) {
    let url = `${this.ServerUrl}api/LoginJM/FetchClientDetailsOnMobileOrEmail`;
    return this.http.post<any>(url, data, headers).pipe();
  }

  fetchSubBrokerDetailsBasedOnMobile(data: string) {
    
    let url = `${this.ServerUrl}api/LoginJM/fetchSubBrokerDetailsOnMobileOrEmail`;
    return this.http.post<any>(url, data, headers).pipe();
  }

  SingleSignOn(TokenId : string, AccountId : string): Observable<any> {
    const url = `${this.ServerUrl}/api/ABML/SingleSignOn`;
    const QueryString = `TokenId=${this.EncrdecrService.setEncr(TokenId)}&AccountId=${this.EncrdecrService.setEncr(AccountId)}`;
    const body = this.ConvertToJSON.QueryStringToJSON(QueryString);
    return this.http.post<any>(url, body).pipe();
  }

  SingleSignOnAuth(authCode : string,version :string): Observable<any> {
    const url = `${this.ServerUrl}/api/ABML/SingleSignOn`;
    const QueryString = `authCode=${this.EncrdecrService.setEncr(authCode)}&version=${this.EncrdecrService.setEncr(version)}`;
    const body = this.ConvertToJSON.QueryStringToJSON(QueryString);
    return this.http.post<any>(url, body).pipe();
  }
  SingleSignOnNew(SloginID : string,SAccountID: string,token :string): Observable<any> {
    const url = `${this.ServerUrl}/api/ABML/SingleSignOn`;
    const QueryString = `SloginID=${this.EncrdecrService.setEncr(SloginID)}&SAccountID=${this.EncrdecrService.setEncr(SAccountID)}&token=${this.EncrdecrService.setEncr(token)}`;
    const body = this.ConvertToJSON.QueryStringToJSON(QueryString);
    return this.http.post<any>(url, body).pipe();
  }
  SingleSignOnUserIDNew(userId : string,authCode: string,token? :string): Observable<any> {
    const url = `${this.ServerUrl}/api/ABML/SingleSignOn`;
    const QueryString = `userId=${this.EncrdecrService.setEncr(userId)}&authCode=${this.EncrdecrService.setEncr(authCode)}`;
    const body = this.ConvertToJSON.QueryStringToJSON(QueryString);
    return this.http.post<any>(url, body).pipe();
  }

  // Added by Saddam jr
  Emp_Sub_SingleSignOn(TK:string,UserType:string): Observable<any> {
    const url = `${this.ServerUrl}/api/ABML/Emp_Sub_SingleSignOn`;
    const QueryString = `TK=${this.EncrdecrService.setEncr(TK)}&UserType=${UserType}`;
    const body = this.ConvertToJSON.QueryStringToJSON(QueryString);
    return this.http.post<any>(url, body).pipe();
  }

  //Added by Saddam jr
  SingleSignOnNSDL(tokenkey:string): Observable<any> {
    const url = `${this.ServerUrl}/api/NSDL/SingleSignOn`;
    const QueryString = `tokenkey=${tokenkey}`;
    const body = this.ConvertToJSON.QueryStringToJSON(QueryString);
    return this.http.post<any>(url, body).pipe();
  }
  //-----------------------------------------------------------------------
  PostLogin_NSE_Info(formData:any,UserType:number,IP:string = ''): Observable<any> {
    debugger
    let DataUrl = `${this.ServerUrl}/api/Authenticate/Authenticate`;
    let fd = JSON.parse(formData);
    let key = this.EncrdecrService.setEncr(this.randomString.getString(6));
    let un = this.EncrdecrService.setEncr(fd.Username);
    let pd = this.EncrdecrService.setEncr(fd.Password);
    pd = this.EncrdecrService.setEncr(pd+'`'+key);
    let ut = UserType;
    let ip = this.EncrdecrService.setEncr(IP);
    let TOKEN = btoa(`${un}:${pd}:${ut}`);
    let req ={
      "USER_ID":un,
      "PWD":pd,
      "USER_TYPE":ut,
      "IPAddress":ip
    }
    const jsonbody = JSON.stringify(req);
    console.log('DataUrl '+DataUrl);
    console.log('jsonbody '+jsonbody);
    console.log('header value  '+ `Authorization : ${key}`);


    const httpOptions = {
      headers:
      new HttpHeaders(
        {'Content-Type': 'application/json', 'Authorization': `${key}`})
    }

    return this.http.post<any>(DataUrl, jsonbody, httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  SendOTP_NSE(body): Observable<any[]> {
    let url = `${this.ServerUrl}/api/OTP`;
    return this.http.post<any[]>(url,body, headers).pipe();
  }

  VerifyOTP_NSE(body): Observable<any[]> {
    let url = `${this.ServerUrl}/api/Forgotpass/VerifyOTP`;
    return this.http.post<any[]>(url,body, headers).pipe();
  }

  ChangePassword_NSE(formData): Observable<any[]> {
    let req = {data: this.EncrdecrService.setEncr(formData)}
    let url = `${this.ServerUrl}/api/Forgotpass/Details`;
    return this.http.post<any>(url, req, headers).pipe();
  }

  //-----------------EMPLOYEE LOGIN-----------------------
  Post_EmployeeLogin(formData:any,UserType:number,IP:string = ''): Observable<any> {
    let DataUrl = `${this.ServerUrl}/api/Authenticate/Authenticate`;
    //let DataUrl = `${this.ServerUrl}/api/Authenticate/Authenticate`;
    let fd = JSON.parse(formData);
    let un = this.EncrdecrService.setEncr(fd.Username);
    let key = this.EncrdecrService.setEncr(this.randomString.getString(6));
    let pd = this.EncrdecrService.setEncr(fd.Password);
    pd = this.EncrdecrService.setEncr(pd+'`'+key);
    let ut = UserType;
    let ip = this.EncrdecrService.setEncr(IP);
    let otp = '';
    let Mobile = '';
    if(this.CompayName == 'NSDL'){
      otp = this.EncrdecrService.setEncr(fd.MobileOTP);
      Mobile = this.EncrdecrService.setEncr(fd.MOBILE);
    }
    
    //console.log('un=' + un + '\npd =' + pd);
    let TOKEN = btoa(`${un}:${pd}:${ut}`);
    let req ;
    if(this.CompayName == 'NSDL'){
      req ={
        "USER_ID":un,
        "PWD":pd,
        "USER_TYPE":ut,
        "IPAddress":ip,
        "MobileOTP" : otp,
        "CompanyName" : this.CompayName,
        "MOBILE": Mobile
      }
    }
    else{
      req ={
        "USER_ID":un,
        "PWD":pd,
        "USER_TYPE":ut,
        "IPAddress":ip,
        "CompanyName" : this.CompayName,
      }
    }
    const jsonbody = JSON.stringify(req);
    console.log(jsonbody);
    console.log(key);

    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json','Authorization': `${key}`
    })
    }
    console.log(DataUrl);
    return this.http.post<any>(DataUrl, jsonbody, httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse) {
    ////////
    //if (error.error instanceof ErrorEvent) {
    //  console.error('An error occurred:', error.error.message);
    // // this.SaveLogFrontEnd(`${error.error.message}`,``);
    //} else {
    //  console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    //  //this.SaveLogBackEnd(`${error.error.message}`,`${error.error}`);
    //}
    // return an observable with a user-facing error message
    //this.errorData = {
    //  status: error.status,
    //  res: error.error
    //};
    return throwError(error);
  }

  test(formData): Observable<any> {
    let DataUrl = `${this.ServerUrl}/api/SMSService/SendOTP`;
    //console.log(DataUrl)
    return this.http.post<any>(DataUrl, formData, headers);
  }

  SaveUserData(formData): Observable<any[]> {
    let url = `${this.ServerUrl}/api/MahindraUser/Details`;
    return this.http.post<any>(url, formData, headers).pipe();
  }

  // SSOLogin(Token:string):Observable<any[]>{

  //   Token = Token.replace(/\+/g,'%2B')
  //   let url = `${this.ServerUrl}/api/AutoLogin/Details?brokerToken=${Token}`;
  //   return this.http.get<any>(url, headers).pipe();
  // }

  SSOLogin(Token:string):Observable<any[]>{

    Token = Token.replace(/\+/g,'%2B')
    let url = `${this.ServerUrl}/api/AutoLogin/Details?brokerToken=${Token}`;
    const f_url = url.split('?')[0];
    const QueryString = url.split('?')[1];
    const body = this.ConvertToJSON.QueryStringToJSON(QueryString);
    return this.http.post<any>(f_url,body,headers).pipe();
  }
  AlankitSSOLogin(Token:string):Observable<any[]>{

    Token = Token.replace(/\+/g,'%2B')
    let url = `${this.ServerUrl}/api/AlankitSSO/Details?brokerToken=${Token}`;
    return this.http.get<any>(url, headers).pipe();
  }

PostDomain(body: any): Observable<any> {
  let url = `${this.ServerUrl}/api/GetCompanydetails/reportingpersonemailpassing`;
  return this.http.post<any[]>(url, body,headers).pipe();
}

AccessWiseData(formData): Observable<any[]> {
  let url = `${this.ServerUrl}/api/Menu/AccessUserData`;
  return this.http.post<any>(url, formData, headers).pipe();
}
GetQRCode(formData): Observable<any[]> {
  let url = `${this.ServerUrl}/api/GenerateQRCode/Details`;
  return this.http.post<any>(url, formData, headers).pipe();
}

GetEmpMobile(formData) : Observable<any[]>{
  let url = `${this.ServerUrl}/api/GetCompanydetails/GetEmpMobileNo`;
  const req = {
    data:this.EncrdecrService.setEncr(formData)
  }
  return this.http.post<any>(url,req,headers).pipe();
}

}
