import { Component, HostListener, OnInit } from '@angular/core';
import { ClientDataService } from './../services/client-data.service';
@Component({
  selector: 'app-header-pre-login',
  templateUrl: './header-pre-login.component.html',
  styleUrls: ['./header-pre-login.component.scss']
})
export class HeaderPreLoginComponent implements OnInit {
  logo: string;
  IsMobile: boolean = false;
  isHidden: boolean = false;
  logoHeight: string= '50px';
  CompanyName: string;
  NavHeight: string = '62px';
  constructor(private ClientDataService: ClientDataService) { }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.SetViewPort(event.target.innerWidth)
  }
  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    this.checkScroll();
  }
  private checkScroll() {
    this.isHidden = window.scrollY > 50; // Adjust the threshold as needed
  }
  ngOnInit() {
    this.SetViewPort(window.innerWidth);
    this.logo = sessionStorage.getItem('logo');
    this.CompanyName = this.ClientDataService.GetCompanyName();
    if(this.IsMobile){
      this.logoHeight= '38px';
    }
    if(this.CompanyName == "NSDL"){
      this.logoHeight= '38px';
    }
  }
  SetViewPort(w) {
    if (w <= 767) {
      this.IsMobile = true;
    }
    else {
      this.IsMobile = false;
    }
  }
}
