import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { objectEach } from 'highcharts';
import { environment } from 'src/environments/environment';
import { EncrdecrService } from '../Auth/encrdecr.service';
import { ConvertToJSONService } from './convert-to-json.service';

@Injectable({
  providedIn: 'root'
})
export class ClientsFilterService {

  private DropDownData: any;
  private ClientData : any;
  constructor( 
    private http:HttpClient,
    private Encrdecr:EncrdecrService,
    private ConvertToJSON:ConvertToJSONService
    ) { }

   WrapperApiCall(data:any,apiSubUrl:string){
    debugger
    const header = this.GetToken();  
    let url = environment.ServerUrl+apiSubUrl;
    return this.http.post(url,data,header).pipe();
  }

  //dropdownvalues
  setSelectedDropdownData(data : any){
    this.DropDownData = data;
  }
 
  getSelectedDropdownData(){
    return this.DropDownData;
  }
   setClientDropdownData(data:any){
    this.ClientData = data;
  }
  getClientDropdownData(){
    return this.ClientData;
  }
  GetToken(){
    let token = sessionStorage.getItem('`huR,8@RK9^??b4 -.hfs!!uR,XX');
    let UserId = this.Encrdecr.getDecr(sessionStorage.getItem('m5JkoXISmYRAIuY'));
    let UserType = this.Encrdecr.getDecr(sessionStorage.getItem('L2D3506kIHSk3E0'));
    let IpAddress = sessionStorage.getItem('Tgsh@#3734fjfskshh==');
    console.log('token value is '+ token +"@"+UserId+"|"+UserType+"|"+IpAddress);
    const TOKEN = btoa(token +"@"+UserId+"|"+UserType+"|"+IpAddress);
     const tokenOption = { headers:
      new HttpHeaders(
        {'Content-Type': 'application/json',
        'Authorization': `Basic ${TOKEN}`,
      })
    }
    return tokenOption;
  }
}
