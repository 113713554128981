<!doctype html>
<html lang="en">

<body>
    <header class="padding20 mb-3">
        <title>Aditya Birla Money Ltd.</title>
        <div class="container mt-5">
            <div class="row">
                <div class="col-md-12 text-center">
                    <img src="../assets/images/JMLogo.png" alt="Logo" class="logo">
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 mt-6 text-center">
                    <h2 class="fontStl">
                        <p>Thank you for making a payment your FD has been booked.</p>
                    </h2>
                </div>
            </div>
        </div>
    </header>
</body>

</html>