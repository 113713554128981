import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { MainHeaderMenuService } from '../../services/main-header-menu.service';
import { EncrdecrService } from '../../Auth/encrdecr.service';
import { LoginService } from '../../services/login.service';
import { TokenExpireService } from 'src/app/services/token-expire.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TransactionService } from '../../services/transaction.service';
import { CustomLoaderService } from 'src/app/services/custom-loader.service';
import * as copy from 'copy-to-clipboard';
import { NotifierService } from "angular-notifier";
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ClientDataService } from '../../services/client-data.service';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';
import { NseService } from './../../services/nse.service';
declare var $: any;

declare var require: any;
const FileSaver = require("file-saver");
@Component({
  selector: 'app-header-jm',
  templateUrl: './header-jm.component.html',
  styleUrls: ['./header-jm.component.scss'],
  animations: [
    trigger("openClose", [
      // ...
      state(
        "open",
        style({
          // height: 'auto',
          opacity: 1,
          display: "block",
        })
      ),
      state(
        "closed",
        style({
          // height: '0px',
          opacity: 0,
          display: "none",
        })
      ),
      transition("open => closed", [animate("0s")]),
      transition("closed => open", [animate("0.3s")]),
    ]),
  ],
  host: {
    "(document:click)": "onClickOutSide($event)",
  },
})
export class HeaderJmComponent implements OnInit {
  logo: string;
  logoHeight: string= '50px';
  CompanyName: string;
  LoginToken: string = '';
  IsCollapseSideMenu: boolean = false;
  isLoggedIn$: Observable<boolean>;
  @Output() isCollapseMenu: EventEmitter<any> = new EventEmitter();
  IsMobile: boolean = false;
  UserName: string = '';
  UserType: string = '';
  IsHomeActive: boolean = true;
  IsActNowActive: boolean = false;
  IsAddActive: boolean = false;
  IsLoyaltyActive: boolean = false;
  IsMenuActive: boolean = false;
  MenuList: any[];
  data: any[];
  IsShow: boolean = false;
  isOnlySingleMenu: boolean = true;
  isSSOLogin:boolean= true;
  CBID: any = '';
  QRCodeImage: any = '';
  QRCodeURL: any = '';
  CopyTest: any = 'Copy URL';
  clientid : any;
  private notifier: NotifierService;
  private _eref: any;
  countcart:any = sessionStorage.getItem("vsdasdasldjod");
  Count: any;
  iscart:boolean=false;
  ExchangeFlag:string;
  RoleId:string;
  isHidden = false;
  constructor(private MainHeaderMenuService: MainHeaderMenuService,
    private transaction: NseService,
    private ClientDataService: ClientDataService,
    private EncrdecrService: EncrdecrService,
    private LoginService: LoginService,
    private Logout: TokenExpireService,
    private router: Router,
    private route: ActivatedRoute,
    private TransactionService: TransactionService,
    private Loader: CustomLoaderService,
    private location: Location) { }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.SetViewPort(event.target.innerWidth)
  }
  @HostListener("window:scroll", ["$event"])
  onScroll(event) {
    $("#OthersMenuUl").slideUp();
    $("#OthersMenuUl2").slideUp();
  }
  ngOnInit() {

    this.logo = sessionStorage.getItem('logo');
    this.CompanyName = this.ClientDataService.GetCompanyName();
    this.SetViewPort(window.innerWidth);
    this.isLoggedIn$ = this.MainHeaderMenuService.isShowHeader;
    this.LoginToken = sessionStorage.getItem("`huR,8@RK9^??b4 -.hfs!!uR,XX");
    let ut = sessionStorage.getItem('L2D3506kIHSk3E0');


  if(this.CompanyName=='NSDL'){
  let isSSO='';
  setTimeout(() => {
    debugger;
    isSSO=sessionStorage.getItem("SSOLOGIN");  
    if(isSSO!=undefined && isSSO !=null && isSSO=='Y'){
      this.isSSOLogin=false;
    }
    else{
      this.isSSOLogin=true;
    }
  }, 2000);

}
   
    //sessionStorage.removeItem("SSOLOGIN")
    if (ut != undefined && ut != null) {
      this.UserType = this.EncrdecrService.getDecr(ut);
    }

    if(this.UserType == '1'){
    this.RoleId = this.EncrdecrService.getDecr(sessionStorage.getItem('5gE4QWzFXWKewRC'));
    }
    let name = sessionStorage.getItem('V4WGROuLLBE6t98');
    if (name != undefined && name != null) {
      this.UserName = this.EncrdecrService.getDecr(name);
    }
    if(this.CompanyName == "NSDL"){
      this.logoHeight= '38px';
    }
    let tempClientBasicInfoId = sessionStorage.getItem('N1QJHdOkO7Hz8QC');
    if (tempClientBasicInfoId != null && tempClientBasicInfoId != undefined) {
      this.clientid = this.EncrdecrService.getDecr(sessionStorage.getItem('N1QJHdOkO7Hz8QC'));
    }
    if(this.CompanyName == 'ABML_Money' && this.UserType =='3'){
        this.GetCartItemCount(this.clientid,'');
    }
    else{
      if(this.UserType =='3'){
        this.GetCartItemCount_NSE(this.clientid)
      }
      else{

      }
    } 
     
    this.BindMenu();
    // if(this.location.path() =="/transaction-new/cart")
    // {
    //   this.iscart=false;
    // }
    // else{
    //   this.iscart=true;
      
    // }
      if (environment.CompayName == "Alankit" || environment.CompayName == "NSDL") {
        this.ExchangeFlag = "N";
      }
      else{
        this.ExchangeFlag = "B";
      }
  }
  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    this.checkScroll();
  }
  private checkScroll() {
    // debugger
    const scrollContainer = document.querySelector('.Second_Band') || window;
    this.isHidden = window.scrollY > 0; // Adjust the threshold as needed
  }
  //Added by Saddam
  GetCartItemCount(ClientId:any,SubbrokerCode:any){
    this.iscart=true;
    console.log(this.location.path())
   
    this.TransactionService.CartSummaryList(ClientId, SubbrokerCode).subscribe(data => {
      //////
      if (data != null) {
        if (data.Message != null && data.Message != '' && data.Message.indexOf('Not Authorized') > -1) {
          this.Loader.hide();
          this.Logout.LogoutUser();
          return;
        }
        else {
          if (data.ArrayOfResponse.length > 0) {

            this.Count = data.ArrayOfResponse.length;
            //console.log(this.Count);
          }
          }
      }
    }
  )}

  GetCartItemCount_NSE(ClientId:any){
   
    this.iscart=true;
    console.log(this.location.path())
   
    this.transaction.CartSummnaryCount(ClientId).subscribe(data => {
      //////
      if (data != null) {
        if (data.Message != null && data.Message != '' && data.Message.indexOf('Not Authorized') > -1) {
          this.Loader.hide();
          this.Logout.LogoutUser();
          return;
        }
        else {
          if (data.ArrayOfResponse.length > 0) {

            this.Count = data.ArrayOfResponse.length;
           // console.log(this.Count);
          }
        }
      }
    }
  )}
  BindMenu() {
     
    let CBId = sessionStorage.getItem('qAkZrQYLWNBSlki'); //Session_CBId
      if (CBId != null && CBId != undefined && CBId != '') {
        this.CBID = this.EncrdecrService.getDecr(CBId);
      }
    if (this.UserType=="1") {
      
      // let SBCode = this.EncrdecrService.getDecr(CBId);
      console.log("User Id is " + this.CBID);
      const json = {
        "userid": this.CBID
      }
      let data = JSON.stringify(json);
     
      this.LoginService.AccessWiseData(data).subscribe(
        (res: any) => {
           
          console.log(res);
          if (res.ArrayOfResponse != null && res.ArrayOfResponse.length > 0) {
            let Singlemenu = res.ArrayOfResponse[0].SubMenuName;
            let t = res.ArrayOfResponse[0];
            let r = res.ArrayOfResponse[0].SubMenuURL;
            if (Singlemenu == null) {
              this.isOnlySingleMenu = true;
            } else {
              this.isOnlySingleMenu = false;
            }
            this.IsShow = true;
            this.MenuList = res.ArrayOfResponse;
            this.data = res.ArrayOfResponse[0].data;
          }
          else {
            this.IsShow = false;
          }
        });
    }

  }
  SetViewPort(w) {
    if (w <= 767) {
      this.IsMobile = true;
    }
    else {
      this.IsMobile = false;
    }
  }
  ngAfterViewInit() {
    $(document).ready(function () {
      setTimeout(() => {
        $("img.svg").each(function () {
          var $img = $(this);
          var imgID = $img.attr("id");
          var imgClass = $img.attr("class");
          var imgURL = $img.attr("src");

          $.get(
            imgURL,
            function (data) {
              // Get the SVG tag, ignore the rest
              var $svg = $(data).find("svg");

              // Add replaced image's ID to the new SVG
              if (typeof imgID !== "undefined") {
                $svg = $svg.attr("id", imgID);
              }
              // Add replaced image's classes to the new SVG
              if (typeof imgClass !== "undefined") {
                $svg = $svg.attr("class", imgClass + " replaced-svg");
              }

              // Remove any invalid XML tags as per http://validator.w3.org
              $svg = $svg.removeAttr("xmlns:a");

              // Replace image with new SVG
              $img.replaceWith($svg);
            },
            "xml"
          );
        });
      }, 1000);

    });
  }
  OnClickHamerburg() {
    this.IsCollapseSideMenu = !this.IsCollapseSideMenu;
    this.isCollapseMenu.emit(this.IsCollapseSideMenu);
  }
  onClickFooterMenu(tab: string) {
    this.IsHomeActive = false;
    this.IsActNowActive = false;
    this.IsAddActive = false;
    this.IsLoyaltyActive = false;
    this.IsMenuActive = false;

    switch (tab) {
      case 'Home': {
        this.IsHomeActive = true;
        this.IsActNowActive = false;
        this.IsAddActive = false;
        this.IsLoyaltyActive = false;
        this.IsMenuActive = false;
        this.GoToHome();
        break;
      }
      case 'Act': {
        this.IsActNowActive = true;
        this.IsHomeActive = false;
        this.IsAddActive = false;
        this.IsLoyaltyActive = false;
        this.IsMenuActive = false;
        break;
      }
      case 'Add': {
        this.IsAddActive = true;
        this.IsHomeActive = false;
        this.IsActNowActive = false;
        this.IsLoyaltyActive = false;
        this.IsMenuActive = false;
        this.OpenOthersMenu();
        break;
      }
      case 'Loyalty': {
        this.IsLoyaltyActive = true;
        this.IsHomeActive = false;
        this.IsActNowActive = false;
        this.IsMenuActive = false;
        this.IsAddActive = false;
        break;
      }
      case 'Menu': {
        this.IsMenuActive = true;
        this.IsLoyaltyActive = false;
        this.IsHomeActive = false;
        this.IsActNowActive = false;
        this.IsAddActive = false;
        this.OpenOthers();
        break;
      }
      default: {
        // this.IsHomeActive = true;
        break;
      }
    }
  }
  OpenOthersMenu() {
    $(".fixed-bottom.navbar .navbar-nav .nav-item").removeClass("active");
    $("#IdOthersMenu").addClass("f-active");
    $("#IdOtherImage").addClass("f-active");
    $("#OthersMenuUl").slideToggle();
    $('#overlay').css('display', 'block');
  }
  OpenOthers() {
    $(".fixed-bottom.navbar .navbar-nav .nav-item").removeClass("active");
    $("#IdOthersMenu").addClass("f-active");
    $("#IdOtherImage").addClass("f-active");
    $("#OthersMenuUl2").slideToggle();
    $('#overlay').css('display', 'block');
  }
  LogOut() {
    this.LoginService.Logout(this.LoginToken).subscribe((res: any) => { });
    sessionStorage.removeItem("Session_SubBrokerId");
    sessionStorage.removeItem("Session_SubBrokerCode");
    sessionStorage.removeItem("`huR,8@RK9^??b4 -.hfs!!uR,XX");
    sessionStorage.removeItem("SSOLOGIN");
    //if (this.UserType == '2') {
    //this.router.navigate(["/cp-login"], { relativeTo: this.route });
    //}
    //else {
    this.Logout.LogoutUser();
    //}
  }
  GoToHome() {
    if (this.UserType == '1') {
      this.router.navigate(['/mf-dashboard'], { relativeTo: this.route });
    }
    else if (this.UserType == '2') {
      this.router.navigate(['/sb-dashboard'], { relativeTo: this.route });
    }
    else {
      this.router.navigate(['/client-dashboard'], { relativeTo: this.route });
    }
  }
  GoToProfile() {
    if (this.UserType == '3') {
      this.Loader.show();
if(this.ExchangeFlag=='B'){
  this.TransactionService.GetClientUCCDetails(this.CBID).subscribe(
    (res: any) => {
      this.Loader.hide();
      if (res != null) {
        if (
          res.Message != null &&
          res.Message != "" &&
          res.Message.indexOf("Not Authorized") > -1
        ) {
          return;
        }
        else {
          if (res.ArrayOfResponse.length > 0) {
            let G_UCCCode = res.ArrayOfResponse[0].ClientCode;
            sessionStorage.setItem('N1QJHdOkO7Hz8QC', this.EncrdecrService.setEncr(this.CBID.toString()));
            sessionStorage.setItem('W113TKilrF1u1gL', this.EncrdecrService.setEncr(G_UCCCode.toString()));
            this.router.navigate(['/client-profile'], { relativeTo: this.route });
          }
          else {
            sessionStorage.setItem('3erT@Qr65TdXwq1', this.EncrdecrService.setEncr(this.CBID));
            this.router.navigate(['/Client-Profile-Preview/Preview'], { relativeTo: this.route });
          }
        }
      }
    },
    (err) => {
      this.Loader.hide();
    }
  );
}

else{ 
  this.TransactionService.GetClientIINDetails(this.CBID).subscribe(
  (res: any) => {
    this.Loader.hide();
    if (res != null) {
      if (
        res.Message != null &&
        res.Message != "" &&
        res.Message.indexOf("Not Authorized") > -1
      ) {
        return;
      }
      else {
        if (res.ArrayOfResponse.length > 0) {
          let G_UCCCode = res.ArrayOfResponse[0].ClientCode;
          sessionStorage.setItem('N1QJHdOkO7Hz8QC', this.EncrdecrService.setEncr(this.CBID.toString()));
          sessionStorage.setItem('W113TKilrF1u1gL', this.EncrdecrService.setEncr(G_UCCCode.toString()));
          this.router.navigate(['/client-profile'], { relativeTo: this.route });
        }
        else {
          sessionStorage.setItem('3erT@Qr65TdXwq1', this.EncrdecrService.setEncr(this.CBID));
          this.router.navigate(['/Client-Profile-Preview/Preview'], { relativeTo: this.route });
        }
      }
    }
  },
  (err) => {
    this.Loader.hide();
  }
);

}
    }
    else if (this.UserType == '2') {
      let loginId = this.EncrdecrService.getDecr(sessionStorage.getItem("F44sGAGh2xwkpUL"))
      sessionStorage.setItem("Khgo235hklk", "true");
      sessionStorage.setItem("RpkW60j5hsYGPRA", "IFD");
      sessionStorage.setItem('7X5pOZwlTjPjtb4D0p', this.EncrdecrService.setEncr(loginId));
      this.router.navigateByUrl('/onboarding-preview');
    }
  }

  ShowQRCode() {
    this.CopyTest = "Copy URL"
 
    const json = {
      "EmpCode": this.CBID,
      "UserType": this.UserType
    }
    let data = JSON.stringify(json);
    this.LoginService.GetQRCode(data).subscribe(
      (res: any) => {
        console.log(res);
        if (res.ArrayOfResponse != null && res.ArrayOfResponse.length > 0) {
          this.QRCodeImage = "data:image/png;base64," + res.ArrayOfResponse[0].Base64String;
          this.QRCodeURL = res.ArrayOfResponse[0].URL
          $("#QRCode").modal({ backdrop: "static", keyboard: false });

        }
        else {
          this.QRCodeImage = null;
        }
      });
  }
  DownloadQRCode() {
    
    try {
      let url = this.QRCodeImage.split("base64,")[1];
      if (url != null && url != "" && url != undefined) {
        // var a = url.split('|')[1];

        const b64toBlob1 = (url, contentType = '', sliceSize = 512) => {
          const byteCharacters1 = atob(url);
          const byteArrays1 = [];

          for (let offset = 0; offset < byteCharacters1.length; offset += sliceSize) {
            const slice1 = byteCharacters1.slice(offset, offset + sliceSize);

            const byteNumbers1 = new Array(slice1.length);
            for (let i = 0; i < slice1.length; i++) {
              byteNumbers1[i] = slice1.charCodeAt(i);
            }

            const byteArray1 = new Uint8Array(byteNumbers1);
            byteArrays1.push(byteArray1);
          }

          const blob12 = new Blob(byteArrays1, { type: contentType });

          return blob12;
        }

        const blob1 = b64toBlob1(url, 'png');
        //const blob1 = b64toBlob1(url, fileType);
        const blobUrl1 = URL.createObjectURL(blob1);

        // FileSaver.saveAs(blobUrl1, `Doc_${Type}.${fileType}`);

        FileSaver.saveAs(blobUrl1, `MyQR.png`);
      }

      else {

      }
    }
    catch (err) {

    }
  }

  CopyURL() {
    copy(this.QRCodeURL);
    this.CopyTest = "Copied"
    setTimeout(() => {
      this.CopyTest = "Copy URL"
    }, 5000);
  }
  onClickOutSide(event) {
    // if (!this._eref.nativeElement.contains(event.target)) {
    //   // or some similar check
    //   // this.IsMyAccountOpen = false;
    // }
    $(document).mouseup(function (e) {
      var container = $("#IdOthersMenu");
      if (!container.is(e.target) && container.has(e.target).length === 0) {
        $("#OthersMenuUl").slideUp();
        $("#OthersMenuUl2").slideUp();
        $('#overlay').css('display', 'none');
      }
    });
  }
  CloseMenu() {
    $("#OthersMenuUl").slideUp();
    $("#OthersMenuUl2").slideUp();
  }


  ReplaceChar(value: string) {
    
    if (value != null && value != '' && value != undefined) {
      value = value.trim();
    }
    if (value.includes(' ')) {
      value = value.replace(' ', '_');
    }
    if (value.includes('/')) {
      value = value.replace('/', '_');
    }
    return value;
  }

  GetImgSource(flag: string) {
     
    if (flag != null && flag != '' && flag != undefined) {
      flag = flag.trim();
    }
    let imgSrc = '';
    switch (flag) {
      case 'Home':
        imgSrc = '../../../assets/images/Sidebar/Home_new.svg';
        break;

      case 'Dashboard Client':
        imgSrc = '../../../assets/images/Sidebar/Dashboard-Client.svg';
        break;

      case 'Dashboard IFD':
        imgSrc = '../../../assets/images/Sidebar/Dashboard-IFD.svg';
        break;

      case 'Online Transaction':
        imgSrc = '../../../assets/images/Sidebar/Online_Transaction.svg';
        break;

      case 'Calculators':
        // case 'Goal Based Investing':
        imgSrc = '../../../assets/images/Sidebar/Goal_Based_Investing.svg';
        break;

      case 'FD':
        imgSrc = '../../../assets/images/Sidebar/FD.svg';
        break;

      case 'Subbroker':
        imgSrc = '../../../assets/images/Sidebar/Subbroker.svg';
        break;

      case 'Campaign Management':
        imgSrc = '../../../assets/images/Sidebar/Campaign_Management.svg';
        break;

      case 'SGB':
        imgSrc = '../../../assets/images/Sidebar/SGB.svg';
        break;

      case 'IPO/NCD':
        imgSrc = '../../../assets/images/Sidebar/NCD.svg';
        break;

      case 'Clients':
        imgSrc = '../../../assets/images/Sidebar/Clients.svg';
        break;

      case 'Explore Funds':
        imgSrc = '../../../assets/images/Sidebar/Explore_Funds.svg';
        break;

      case 'Corporate IFD':
        imgSrc = '../../../assets/images/mapping.svg';
        break;

      case 'Reports':
        imgSrc = '../../../assets/images/Sidebar/Reports.svg';
        break;

      case 'Reconciliation':
        imgSrc = '../../../assets/images/Sidebar/Reconciliation.svg';
        break;
    }
    return imgSrc;
  }
}
