import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ConvertToJSONService } from './convert-to-json.service';
import { Observable } from 'rxjs';
const headers = { headers: new HttpHeaders({ 'content-type': 'application/json' }) }
@Injectable({
  providedIn: 'root'
})
export class ClientDataService {
  private WelcomeText:any;
  private CompanyName:any;
  ServerUrl = environment.ServerUrl;
  private IsCollapse:boolean;

  constructor(private http: HttpClient,private ConvertToJSON: ConvertToJSONService) { }
  SetWelcomeText(message: string) {
    this.WelcomeText = message;
  }
  GetWelcomeText() {
    return this.WelcomeText;
  }
  SetCompanyName(name: string) {
    this.CompanyName = name;
  }
  GetCompanyName() {
    return this.CompanyName;
  }

  clearData() {
    this.WelcomeText = '';
    this.CompanyName = '';
  }
  getCompanyDetails(CompanyId) {
    let Url = `${this.ServerUrl}/api/CompanyDetails/Details`;
    const QueryString = `Flag=CompanyWise&CompanyId=${CompanyId}`;
    const body = this.ConvertToJSON.QueryStringToJSON(QueryString);
    return this.http.post<any>(Url,body,headers);
  }
  SetIsCollapse(para){
    this.IsCollapse = para;
  }
  GetIsCollapse(){
    return this.IsCollapse;
  }

  //Added By Devang Mehta On 02-07-2024
  GetCartURL(formData:any): Observable<any> {
    debugger
    //const header = this.GetToken();
    let url = `${this.ServerUrl}/api/CartMF/CartURLDetails`
    return this.http.post<any>(url,formData ,headers).pipe();
  }
  //End by Devang Mehta

}
