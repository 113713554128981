
import { isNullOrUndefined } from './validation';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { Component, HostListener } from '@angular/core'
import { Title } from '@angular/platform-browser';
import { BnNgIdleService } from 'bn-ng-idle';
import { ClientDataService } from './services/client-data.service';
import { EncrdecrService } from './Auth/encrdecr.service';
import { LoginService } from './services/login.service';
import { CustomLoaderService } from './services/custom-loader.service';
import { TokenExpireService } from './services/token-expire.service';
import { MainHeaderMenuService } from './services/main-header-menu.service';
import { Observable } from 'rxjs';
import { Location } from '@angular/common';
declare var $: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  // Companyname: string = 'AU';
  CompanyLogo:any;
 href : any;
 IsLoaded = false;
  Companyname: string = environment.CompayName;
  CommonBaseHref:string = environment.CommonBaseHref;
  PrimaryColor: string = '';
  SecondaryColor: string = '';
  ThirdColor: string = '';
  BodyColor: string = '';
  BodyBgColor: string = '';
  BodyFooterBgColor: string = '';
  Table_Header_Bg = '';
  Table_Head_Text = '';
  fontFamily = '';
  favIcon: HTMLLinkElement = document.querySelector('#Fevicon');
  //MainLogo: HTMLImageElement = document.querySelector('#MainLogo');
  logo: string;
  loader: string;
  loaderWidth: string;
  loaderHeight: string;
  SessionTime: number = 300; //Changed By Devang Mehta On 14-06-2024 for NSDL VAPT
  IsMobile: boolean = false;
  IsLoggedIn: boolean = false;
  CheckHeader : boolean = false;
  isLoggedIn$: Observable<boolean>;
  IsCollapse:boolean = false;
  constructor(private Idle: BnNgIdleService,
    private titleService: Title,
    private ClientDataService: ClientDataService,
    private EncrdecrService: EncrdecrService,
    private LoginService: LoginService,
    private router: Router,
    public Loader: CustomLoaderService,
    private Logout:TokenExpireService,
    private MainHeaderMenuService:MainHeaderMenuService,
    private location: Location,
  ) { // initiate it in your component constructor
    //sessionStorage.removeItem('6+J3RebINEPBJVbAJw');
    //Added By Devang Mehta on 14-06-2024 for NSDL VAPT
    if(this.Companyname == "ABML_Money"){
      this.SessionTime = 28800;
    }
    //End By Devang Mehta

    this.Idle.startWatching(this.SessionTime).subscribe((res) => {
      if (res) {
        this.MainHeaderMenuService.hide();
        if (sessionStorage.getItem('5V5uSYBoBVqAvBIBqUEBhv6ELFF') != null && sessionStorage.getItem('5V5uSYBoBVqAvBIBqUEBhv6ELFF') != undefined && sessionStorage.getItem('5V5uSYBoBVqAvBIBqUEBhv6ELFF') != "") {
          const isLoggtedIn = this.EncrdecrService.getDecr(sessionStorage.getItem('5V5uSYBoBVqAvBIBqUEBhv6ELFF'));
          if (isLoggtedIn == "1") {
            this.IsLoggedIn = true;
          }
          else {
            this.IsLoggedIn = false;
          }
        } else {
          this.IsLoggedIn = false;
        }
        if (res == true) {

          switch (this.Companyname) {
              case 'ABML_Money':
                this.ClientDataService.SetWelcomeText('Aditya Birla Money Ltd.');
                this.titleService.setTitle('Aditya Birla Money Ltd.');
                sessionStorage.setItem('logo', '../../assets/images/ABMLband_Logo.jpg');
                sessionStorage.setItem('Abodhj#@jdkd/%^82ncdbcnggshd!@#$', this.EncrdecrService.setEncr("19"));
                sessionStorage.setItem('Abodhj#@jdkd/%^ysudsh&#&shdj@#$', "19");
                sessionStorage.setItem('Mnuoisns6wqgswj2$#', "1");
                break;

              case 'Alankit':
                this.ClientDataService.SetWelcomeText('Alankit');
                this.titleService.setTitle('Alankit');
                sessionStorage.setItem('logo', '../../assets/images/Alankit_logo.svg');
                sessionStorage.setItem('Abodhj#@jdkd/%^82ncdbcnggshd!@#$', this.EncrdecrService.setEncr("20"));
                sessionStorage.setItem('Abodhj#@jdkd/%^ysudsh&#&shdj@#$', "20");
                sessionStorage.setItem('Mnuoisns6wqgswj2$#', "2");
                break;

              case 'NSDL':
                this.ClientDataService.SetWelcomeText('NSDL Payments Bank');
                this.titleService.setTitle('NSDL Payments Bank');
                sessionStorage.setItem('logo', '../../assets/images/NSDL_Logo.svg');
                sessionStorage.setItem('Abodhj#@jdkd/%^82ncdbcnggshd!@#$', this.EncrdecrService.setEncr("21"));
                sessionStorage.setItem('Abodhj#@jdkd/%^ysudsh&#&shdj@#$', "21");
                sessionStorage.setItem('Mnuoisns6wqgswj2$#', "3");
                break;

            case 'Invest4Edu':
              this.ClientDataService.SetWelcomeText('Invest4Edu');
              this.titleService.setTitle('Invest4Edu');
              sessionStorage.setItem('logo', '../../assets/images/investedu_Logo.svg');
              sessionStorage.setItem('Abodhj#@jdkd/%^82ncdbcnggshd!@#$', this.EncrdecrService.setEncr("22"));
              sessionStorage.setItem('Abodhj#@jdkd/%^ysudsh&#&shdj@#$', "22");
              sessionStorage.setItem('Mnuoisns6wqgswj2$#', "4");
              break;
          }
          let path = window.location.pathname.toLowerCase();
          if (path != '/login-corp' && !path.includes("login") && path != '/index' && path != '/ipo' && path != '/ipo/' && path != '/ipo/index' && path != '/' ) {
            $('.modal').modal('hide');

              this.SessionExpired();
              const LoginSource = sessionStorage.getItem('6+J3RebINEPBJVbAJw');
              if (LoginSource == null || LoginSource == undefined || LoginSource == '' && this.Companyname != "AU") {
                $('#SessionModal').modal({
                  backdrop: 'static',
                  keyboard: false
                });
            }
          }
          history.pushState(null, null, window.location.href);
          history.back();
          window.onpopstate = () => history.forward();
          $('select').select2('close');
        }
      }
    })
    //this.getCompanyDetails();
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.SetViewPort(event.target.innerWidth)
  }
  ngOnInit() {
    this.isLoggedIn$ = this.MainHeaderMenuService.isShowHeader;
    this.SetViewPort(window.innerWidth);
    //if(this.Companyname == 'ABML_Money' || this.Companyname == 'Alankit' || this.Companyname == 'NSDL' || this.Companyname == 'Invest4Edu'){
      $('body').css('min-height',window.innerHeight - 66)
      //$('.MainOuter').css('min-height', window.innerHeight - 72);
    //}
    if(this.Companyname == "ABML_Money"){
      if(this.location.path() =="/transaction-new/cart")
      {
        this.CheckHeader=false;
      }
      else{
        this.CheckHeader=true;   
      }
    }
    //
    $('.modal').modal('hide');
    
    this.getCompanyDetails();
    switch (this.Companyname) {
      case 'ABML_Money':
                this.PrimaryColor = '#c91429'; //#c91429
                this.SecondaryColor = '#99a26f'; //#8d1218
                this.ThirdColor = '#f7a500';
                this.BodyColor = '#1a1a1a';
                this.BodyBgColor = '#99a26f' // FEFCCD
                this.BodyFooterBgColor = '#0e75bd';
                this.Table_Header_Bg = '#99a26f'; //#969f6e
                this.Table_Head_Text = '#ffffff';
                this.fontFamily = 'PFHandbookPro';
                this.ClientDataService.SetWelcomeText('Aditya Birla Money Ltd.');
                this.titleService.setTitle('Aditya Birla Money Ltd.');
                this.favIcon.href = './assets/images/favicon_ABFL.ico';
                this.loader = '../../../assets/images/Loader_ABFL.gif';
                this.loaderWidth = '90px';
                this.loaderHeight = '90px';
                sessionStorage.setItem('logo', '../../assets/images/ABMLband_Logo.jpg');
                sessionStorage.setItem('Abodhj#@jdkd/%^82ncdbcnggshd!@#$', this.EncrdecrService.setEncr("19"));
                sessionStorage.setItem('Mnuoisns6wqgswj2$#', "1");
                break;
      case 'Alankit':
                this.PrimaryColor = '#ff6600'; // Old color: #0275f3 (07092023)  //#032B5B
                this.SecondaryColor = '#1e365d'; // Old color: #343a40 (07092023) //#00397e #0e284c
                this.ThirdColor =  '#afdc35'; // Old color: #00397e (07092023)
                this.BodyColor = '000000';
                this.BodyBgColor = '#E7E9EB' // #FEFCCD
                this.BodyFooterBgColor = '#0064ac';
                this.Table_Header_Bg = '#1e365d'; //#ECECEC
                this.Table_Head_Text = '#ffffff';//#000000
                this.fontFamily = 'SegoeUI';
                this.ClientDataService.SetWelcomeText('Alankit');
                this.titleService.setTitle('Alankit');
                this.favIcon.href = './assets/images/alankit_favicon.png';
                this.loader = '../../../assets/images/Loader_Alankit.gif';
                this.loaderWidth = '130px';
                this.loaderHeight = '130px';
                sessionStorage.setItem('logo', '../../assets/images/Alankit_logo.svg');
                sessionStorage.setItem('Abodhj#@jdkd/%^82ncdbcnggshd!@#$', this.EncrdecrService.setEncr("20"));
                sessionStorage.setItem('Mnuoisns6wqgswj2$#', "2");
                break;
      case 'NSDL':
                this.PrimaryColor = '#e9a42d'; //#032B5B
                this.SecondaryColor = '#8c0000'; //#1b1b1b
                this.ThirdColor = '#1b1b1b';
                this.BodyColor = '000000';
                this.BodyBgColor = '#E7E9EB' // FEFCCD
                this.BodyFooterBgColor = '#0064ac';
                this.Table_Header_Bg = '#e9a42d';
                this.Table_Head_Text = '#ffffff';
                this.fontFamily = 'OpenSans';
                this.ClientDataService.SetWelcomeText('NSDL Payments Bank');
                this.titleService.setTitle('NSDL Payments Bank');
                this.favIcon.href = './assets/images/favicon_NSDL.ico';
                this.loader = '../../../assets/images/Loader_NSDL.gif';
                this.loaderWidth = '130px';
                this.loaderHeight = '130px';
                sessionStorage.setItem('logo', '../../assets/images/NSDL_Logo.svg');
                sessionStorage.setItem('Abodhj#@jdkd/%^82ncdbcnggshd!@#$', this.EncrdecrService.setEncr("21"));
                sessionStorage.setItem('Mnuoisns6wqgswj2$#', "3");
                break;
      case 'Invest4Edu':
                this.PrimaryColor = '#0275f3'; //#032B5B
                this.SecondaryColor = '#343a40'; //#00397e #0e284c
                this.ThirdColor = '#00397e';
                this.BodyColor = '000000';
                this.BodyBgColor = '#E7E9EB' // #FEFCCD
                this.BodyFooterBgColor = '#0064ac';
                this.Table_Header_Bg = '#5c6877'; //#ECECEC
                this.Table_Head_Text = '#ffffff';//#000000
                this.fontFamily = 'SegoeUI';
                this.ClientDataService.SetWelcomeText('Invest4Edu');
                this.titleService.setTitle('Invest4Edu');
                this.favIcon.href = './assets/images/favi_investedu.svg';
                this.loader = '../../../assets/images/Invest4Edu_Loader.gif';
                this.loaderWidth = '130px';
                this.loaderHeight = '130px';
                sessionStorage.setItem('logo', '../../assets/images/investedu_Logo.svg');
                sessionStorage.setItem('Abodhj#@jdkd/%^82ncdbcnggshd!@#$', this.EncrdecrService.setEncr("22"));
                sessionStorage.setItem('Mnuoisns6wqgswj2$#', "4");
                break;
    }
    this.ClientDataService.SetCompanyName(this.Companyname);
    document.documentElement.style.setProperty('--primary-color', this.PrimaryColor);
    document.documentElement.style.setProperty('--secondary-color', this.SecondaryColor);
    document.documentElement.style.setProperty('--third-color', this.ThirdColor);
    document.documentElement.style.setProperty('--body-color', this.BodyColor);
    document.documentElement.style.setProperty('--body-bg-color', this.BodyBgColor);
    document.documentElement.style.setProperty('--body-footer-bg-color', this.BodyFooterBgColor);
    document.documentElement.style.setProperty('--table-header-bg-color', this.Table_Header_Bg);
    document.documentElement.style.setProperty('--table-header-text-color', this.Table_Head_Text);
    document.documentElement.style.setProperty('--font-family-regular', `${this.fontFamily}Regular`);
    document.documentElement.style.setProperty('--font-family-semibold', `${this.fontFamily}SemiBold`);
    document.documentElement.style.setProperty('--font-family-bold', `${this.fontFamily}Bold`);

  }
  SetViewPort(w) {
    if (w <= 767) {
      this.IsMobile = true;
    }
    else {
      this.IsMobile = false;
    }
  }
  getCompanyDetails() {
    this.ClientDataService.getCompanyDetails(environment.CompanyId).subscribe((data: any) => {
      if (data != null && data.ArrayOfResponse.length != 0) {
        let rk = data.ArrayOfResponse[0];
        sessionStorage.setItem('E#$%#XCha7%*=', this.EncrdecrService.getDecr(rk.Dummy2));
        sessionStorage.setItem('Vbhs@#%LMKIo=', this.EncrdecrService.getDecr(rk.Dummy1));
        sessionStorage.setItem('DufjGDH3843JE', this.EncrdecrService.getDecr(rk.Dummy4));
        sessionStorage.setItem('PfdkSJDNWks2323Ms', this.EncrdecrService.getDecr(rk.Dummy3));
        sessionStorage.setItem('Arh34SDBJSSiF', this.EncrdecrService.getDecr(rk.Dummy5));
        sessionStorage.setItem('Fhd63asja$%Wsdjs9304#@dfvdjfjd', this.EncrdecrService.getDecr(rk.Dummy6));
        sessionStorage.setItem('%^&s8g9b!@fla%g', this.EncrdecrService.getDecr(rk.Dummy7));
        sessionStorage.setItem('JikLG6K4LOdfv', this.EncrdecrService.getDecr(rk.Dummy11));
        if(!isNullOrUndefined(rk.Dummy12)){
        sessionStorage.setItem('Hjgir56hutHLB', this.EncrdecrService.getDecr(rk.Dummy12));
        }

       try
       {
         if(!isNullOrUndefined(this.EncrdecrService.getDecr(rk.Dummy8)))
         {
          sessionStorage.setItem('trtygy$gtdr&dft=', this.EncrdecrService.getDecr(rk.Dummy8));
         }else{
          sessionStorage.setItem('trtygy$gtdr&dft=', 'false');
         }
       }
       catch(e){
        sessionStorage.setItem('trtygy$gtdr&dft=', 'false');
       }
       try
       {
         if(!isNullOrUndefined(this.EncrdecrService.getDecr(rk.Dummy9)))
         {
          sessionStorage.setItem('hgjhfjg&jbfj%dfgs=', this.EncrdecrService.getDecr(rk.Dummy9));
         }else{
          sessionStorage.setItem('hgjhfjg&jbfj%dfgs=', 'false');
         }
       }
       catch(e){
        sessionStorage.setItem('hgjhfjg&jbfj%dfgs=', 'false');
       }
       try
       {
         if(!isNullOrUndefined(this.EncrdecrService.getDecr(rk.Dummy10)))
         {
          sessionStorage.setItem('dsa&hdhbc%ube=', this.EncrdecrService.getDecr(rk.Dummy10));
         }else{
           sessionStorage.setItem('dsa&hdhbc%ube=', 'false');
         }
         sessionStorage.setItem('cIe+MhBUozWO3+mKZd2to=', this.EncrdecrService.getDecr(rk.EnableRiskProfile));
       }
       catch(e){
        sessionStorage.setItem('dsa&hdhbc%ube=', 'false');
       }
      }
    });
  }
  SessionExpired() {

    let LoginToken = sessionStorage.getItem('`huR,8@RK9^??b4 -.hfs!!uR,XX');
    this.LoginService.Logout(LoginToken).subscribe(
      (res: any) => {

        const sessionvalue = sessionStorage.getItem('nvjsd212djskd$#21ksdj');

        if (sessionvalue != undefined && sessionvalue != null && sessionvalue != '') {
          if (sessionvalue == 'C') {
            this.Logout.LogoutUser();
          }
          else if (sessionvalue == 'E') {
            this.router.navigateByUrl('/employee-login');
          }
        }
        sessionStorage.clear();
        localStorage.clear();
        switch (this.Companyname) {
            case 'ABML_Money':
                sessionStorage.setItem('logo', '../../assets/images/ABMLband_Logo.jpg');
                sessionStorage.setItem('Abodhj#@jdkd/%^82ncdbcnggshd!@#$', this.EncrdecrService.setEncr("19"));
                sessionStorage.setItem('Abodhj#@jdkd/%^ysudsh&#&shdj@#$', "19");
                sessionStorage.setItem('Mnuoisns6wqgswj2$#', "1");
                break;
            case 'Alankit':
              sessionStorage.setItem('logo', '../../assets/images/Alankit_logo.svg');
              sessionStorage.setItem('Abodhj#@jdkd/%^82ncdbcnggshd!@#$', this.EncrdecrService.setEncr("20"));
              sessionStorage.setItem('Abodhj#@jdkd/%^ysudsh&#&shdj@#$', "20");
              sessionStorage.setItem('Mnuoisns6wqgswj2$#', "2");
              break;
            case 'NSDL':
              this.ClientDataService.SetWelcomeText('NSDL Payments Bank');
              this.titleService.setTitle('NSDL Payments Bank');
              sessionStorage.setItem('logo', '../../assets/images/NSDL_Logo.svg');
              sessionStorage.setItem('Abodhj#@jdkd/%^82ncdbcnggshd!@#$', this.EncrdecrService.setEncr("21"));
              sessionStorage.setItem('Abodhj#@jdkd/%^ysudsh&#&shdj@#$', "21");
              sessionStorage.setItem('Mnuoisns6wqgswj2$#', "3");
              break;
            case 'Invest4Edu':
              sessionStorage.setItem('logo', '../../assets/images/investedu_Logo.svg');
              sessionStorage.setItem('Abodhj#@jdkd/%^82ncdbcnggshd!@#$', this.EncrdecrService.setEncr("22"));
              sessionStorage.setItem('Abodhj#@jdkd/%^ysudsh&#&shdj@#$', "22");
              sessionStorage.setItem('Mnuoisns6wqgswj2$#', "4");
              break;
        }
      }
    );
  }
  RedirectTODashBoard(){
    var G_UserType = parseInt(this.EncrdecrService.getDecr(sessionStorage.getItem('L2D3506kIHSk3E0'))); //Session_UserType
    if(G_UserType==3){
    this.router.navigateByUrl('/client-dashboard');
    }else{
      this.router.navigateByUrl('/mf-dashboard');
    }
  }
  GoToProfilePreview(){
    this.router.navigateByUrl('/Client-Profile-Preview/Preview');
  }
  FnIsCollapseSideMenu(e) {
    if(e){
      $('.MainBg').addClass('collapsed_Menu');
    }
    else{
      $('.MainBg').removeClass('collapsed_Menu');
    }
    this.ClientDataService.SetIsCollapse(e)
  }
  GoToClientProfile(){
    this.router.navigateByUrl('/Client-Profile-Preview/Preview');
  }
}
// ===== Scroll to Top ====
$(window).scroll(function () {
  if ($(this).scrollTop() >= 200) {        // If page is scrolled more than 50px
    $('#return-to-top').fadeIn(200);    // Fade in the arrow
  } else {
    $('#return-to-top').fadeOut(200);   // Else fade out the arrow
  }
});
$(document).ready(function () {
  $('#return-to-top').click(function () {      // When arrow is clicked
    $('body,html').animate({
      scrollTop: 0                       // Scroll to top of body
    }, 500);
  });
  $(".numonly").on("input", function () {
    var regexp = /[^\d\.\-]/g;
    if ($(this).val().match(regexp)) {
      $(this).val($(this).val().replace(regexp, ''));
    }
  });

  $(".alphaonly").on("input", function () {
    var regexp = /[^a-zA-Z ]/g;
    if ($(this).val().match(regexp)) {
      $(this).val($(this).val().replace(regexp, ''));
    }
  });

});


