import { Component } from '@angular/core';

@Component({
  selector: 'app-fd-terms-and-condition',
  templateUrl: './fd-terms-and-condition.component.html',
  styleUrls: ['./fd-terms-and-condition.component.scss']
})
export class FdTermsAndConditionComponent {

}
