<!-- <app-header-jm></app-header-jm> -->
<div class="MainBg" [ngClass]="[showprofilepreview ? 'd-block' : 'd-none',IsCollapse ? 'collapsed_Menu' : '']">
  <div class="BoxShadow MainInnerFormWithoutFlex pb-4 nps">
    <form autocomplete="off" [formGroup]="RiskProfileForm">
  <div class="BoxShadow ProfilePreviewBox">
      <div class="SectionTopHeading mb-0">
          <span>Basic Details</span>
          <!-- <a class="EditDetails CursorPointer ColorGray" (click)='RedirectToProfile("BasicDetails")'>Edit</a> -->
      </div>
      <div class="DetailsBoxOuter px-3 py-4">
          <div class="DetailsBoxInner">
              <div class="lblQ">Full Name</div>
              <div class="lblA">{{firstname}}</div>
          </div>
          <div class="DetailsBoxInner">
              <div class="lblQ">Gender</div>
              <div class="lblA">{{ClientGender}}</div>
          </div>
          <div class="DetailsBoxInner">
              <div class="lblQ">PAN number</div>
              <div class="lblA">{{ClientPanNo}}</div>
          </div>
          <div class="DetailsBoxInner">
              <div class="lblQ">Mobile Number</div>
              <div class="lblA">{{ClientMobileNo}}</div>
          </div>
          <div class="DetailsBoxInner">
              <div class="lblQ">Email Address</div>
              <div class="lblA">{{ClientEmailId}}</div>
          </div>
          <div class="DetailsBoxInner">
              <div class="lblQ">Date of Birth</div>
              <div class="lblA">{{ClientDOB}}</div>
          </div>
      </div>
  </div>
  <div class="BoxShadow ProfilePreviewBox">
      <div class="SectionTopHeading mb-0">
          <span>Address Details</span>
          <!-- <a class="EditDetails CursorPointer ColorGray" (click)='RedirectToProfile("AddressDetails")'>Edit</a> -->
      </div>
      <div class="DetailsBoxOuter px-3 py-4">
          <div class="DetailsBoxInner">
              <div class="lblQ">Address</div>
              <div class="lblA">{{Address1}}</div>
          </div>
          <div class="DetailsBoxInner">
              <div class="lblQ">PIN Code</div>
              <div class="lblA">{{Pincode}}</div>
          </div>
          <div class="DetailsBoxInner">
              <div class="lblQ">City</div>
              <div class="lblA">{{City}}</div>
          </div>
          <div class="DetailsBoxInner">
              <div class="lblQ">State</div>
              <div class="lblA">{{State}}</div>
          </div>
          <div class="DetailsBoxInner">
              <div class="lblQ">Country</div>
              <div class="lblA">{{Country}}</div>
          </div>
      </div>
  </div>
  <div class="BoxShadow ProfilePreviewBox">
      <div class="SectionTopHeading mb-0">
          <span>Nominee Details</span>
          <!-- <a class="EditDetails CursorPointer ColorGray" >Edit</a> -->
      </div>
      <div class="DetailsBoxOuter px-3 py-4">
          <div class="DetailsBoxInner">
              <div class="lblQ">Name</div>
              <div class="lblA">{{NomineeName}}</div>
          </div>
          <div class="DetailsBoxInner">
              <div class="lblQ">Date of Birth</div>
              <div class="lblA">{{NomineeDateofBirth}}</div>
          </div>
          <div class="DetailsBoxInner">
              <div class="lblQ">Relationship With Account Holder</div>
              <div class="lblA">{{relationship}}</div>
          </div>
          <div class="DetailsBoxInner">
              <div class="lblQ">Is Nominee Minor</div>
              <div class="lblA">{{NomineeMinor}}</div>
          </div>
          <div class="DetailsBoxInner">
              <div class="lblQ">Guardian Name</div>
              <div class="lblA">{{GuardianName}}</div>
          </div>
          <div class="DetailsBoxInner">
              <div class="lblQ">Relationship With Guardian</div>
              <div class="lblA">{{GuardianRelation}}</div>
          </div>
      </div>
  </div>
  <div class="BoxShadow ProfilePreviewBox">
      <div class="SectionTopHeading mb-0">
          <span>Bank Details</span>
          <!-- <a class="EditDetails CursorPointer ColorGray"  (click)='RedirectToProfile("BankDetails")'>Edit</a> -->
      </div>
      <div class="DetailsBoxOuter px-3 py-4">
          <div class="DetailsBoxInner">
              <div class="lblQ">IFSC Code</div>
              <div class="lblA">{{IFSCCode}}</div>
          </div>
          <div class="DetailsBoxInner">
              <div class="lblQ">Bank Name</div>
              <div class="lblA">{{BankName}}</div>
          </div>
          <div class="DetailsBoxInner">
              <div class="lblQ">Branch</div>
              <div class="lblA">{{BranchName}}</div>
          </div>
          <div class="DetailsBoxInner">
              <div class="lblQ">Account Number</div>
              <div class="lblA">{{ClientBankAccountNo}}</div>
          </div>
      <div class="DetailsBoxInner">
              <div class="lblQ">Account Type</div>
              <div class="lblA">{{Accountype}}</div>
          </div>
           <div class="DetailsBoxInner">
              <div class="lblQ">MICR Code</div>
              <div class="lblA">{{MICRCode}}</div>
          </div>
      </div>
  </div>
  <div class="BoxShadow ProfilePreviewBox">
      <div class="SectionTopHeading mb-0">
          <span>FATCA Details</span>
          <!-- <a class="EditDetails CursorPointer ColorGray">Edit</a> -->
      </div>
      <div class="DetailsBoxOuter px-3 py-4">
          <div class="DetailsBoxInner">
              <div class="lblQ">Tax Resident</div>
              <div class="lblA">{{IndianTaxResident}}</div>
          </div>
          <div class="DetailsBoxInner">
              <div class="lblQ">Politically Exposed</div>
              <div class="lblA">{{PoliticalExposure}}</div>
          </div>
          <div class="DetailsBoxInner">
              <div class="lblQ">Occupation</div>
              <div class="lblA">{{Occupation}}</div>
          </div>
          <div class="DetailsBoxInner">
            <div class="lblQ">Income Slab</div>
            <div class="lblA">{{GrossAnnualIncome}}</div>
        </div>
          <div class="DetailsBoxInner">
              <div class="lblQ">Relationship With Mobile no. Holder</div>
              <div class="lblA">{{Relationwithemobholder}}</div>
          </div>
          <div class="DetailsBoxInner">
              <div class="lblQ">Relationship With Email Holder</div>
              <div class="lblA">{{Relationwithemailholder}}</div>
          </div>
          <div class="DetailsBoxInner">
              <div class="lblQ">Country Of Birth</div>
              <div class="lblA">{{birthCountry}}</div>
          </div>
      </div>
  </div>
  <div class="BoxShadow ProfilePreviewBox">
      <div class="SectionTopHeading mb-0">
          <span>Risk Profile</span>
          <!-- <a class="EditDetails CursorPointer ColorGray">Edit</a> -->
      </div>
      <div class="px-1 py-1">
          <!-- <div class="DetailsBoxInner">
              <div class="lblQ">Risk Profile Name</div>
              <div class="lblA"><b>Aggressive</b></div>
          </div> -->
          <table class="CommonTable">
              <thead>
                  <th class="">Question Name</th>
                  <th class="">Answers</th>
              </thead>
              <tbody>
                <tr *ngFor="let c of QuestionList;">
                  <td>{{c.QuestionName}} *</td>
                  <td class="text-right">
                    <select [id]="'Risk_' + c.QuestionId"
                      placeholder="&nbsp;" style="font-size:12px !important;"
                      class="form-control CommonSelectBox TbxMarginBtm" disabled>
                      <option value="" disabled selected>--SELECT--</option>
                      <option *ngFor="let ans of c.lstAnswerList;" [value]="ans.AnswerId">{{ans.Answer}}
                      </option>
                    </select>


                  </td>
                </tr>
                <!-- <tr *ngFor="let c of QuestionList;">
                  <td class="lblQ">{{c.QuestionName}} *</td>
                  <td class="lblA" [id]="'Risk_' + c.QuestionId">{{c.lstAnswerList.Answer}}</td>
              </tr> -->
              </tbody>
          </table>
          <div class="px-3 py-3">
              <div class="DetailsBoxInner d-flex">
                  <span class="lblQ">Risk Profile Name</span>&nbsp;&nbsp;
                  <div class="lblA"><b>{{RiskProfileName}}</b></div>
              </div>
          </div>
      </div>
  </div>
  <!-- <div class="BoxShadow ProfilePreviewBox">
      <div class="SectionTopHeading mb-0">
          <span>Mandate Status</span>
          <a class="EditDetails CursorPointer ColorGray" >Edit</a>
      </div>
      <div class="DetailsBoxOuter px-3 py-4">
          <div class="DetailsBoxInner">
              <div class="lblQ">Mandate Type</div>
              <div class="lblA">Active</div>
          </div>
          <div class="DetailsBoxInner">
              <div class="lblQ">Mandate Amount</div>
              <div class="lblA"><i class="fa fa-inr mr-1"></i> 8650</div>
          </div>
          <div class="DetailsBoxInner">
              <div class="lblQ">Mandate Status</div>
              <div class="lblA">Active</div>
          </div>
          <div class="DetailsBoxInner">
              <div class="lblQ">Mandate Start Date</div>
              <div class="lblA">20/08/2022</div>
          </div>
          <div class="DetailsBoxInner">
              <div class="lblQ">Mandate End Date</div>
              <div class="lblA">28/08/2022</div>
          </div>
          <div class="DetailsBoxInner">
              <div class="lblQ">Response MandateId</div>
              <div class="lblA">28/08/2022</div>
          </div>
          <div class="DetailsBoxInner">
              <div class="lblQ">Status</div>
              <div class="lblA">Active</div>
          </div>
          <div class="DetailsBoxInner">
              <div class="lblQ">Action</div>
              <div class="lblA">NA</div>
          </div>
      </div>
  </div> -->
  <div class="mt-4 text-center">
      <button class="CommonSubmitButton" (click)="OnsubmitUccCreate()">Confirm</button>
  </div>
  </form>
</div>
</div>



<div class="modal" id="RiskProfileAlertModel" appDisabledRightClick>
<div class="modal-dialog modal-dialog-centered">
  <div class="modal-content AlertModalContentCls">
    <div class="modal-header AlertModalHeader">Important Message</div>
    <div id="RiskProfileAlertModelContent" class="modal-body AlertModalBody"></div>
    <div class="modal-footer AlertModalFooter">
      <button *ngIf="!ShowLogout" type="button" data-dismiss="modal" routerLinkActive="active"
        class="CursorPointer"> Okay</button>
        <button *ngIf="ShowLogout" type="button" data-dismiss="modal" routerLinkActive="active"
        class="CursorPointer" (click)="LogoutUser()"> Okay</button>
    </div>
  </div>
</div>
</div>


