import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OtpvariableService {
  MobileNo : string ;
  EmailId : string;
  ClientBasicInfoId : string;
  ActionFrom : string ;
  TriggerEventName : string;
  constructor() { }
}
