import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { ConvertToJSONService } from './convert-to-json.service';
import { EncrdecrService } from './../Auth/encrdecr.service';
const headers = { headers: new HttpHeaders({ 'content-type': 'application/json' }) }
@Injectable({
  providedIn: 'root'
})
export class CpOnBoardingService {
  ServerUrl = environment.ServerUrl;
  constructor(private http: HttpClient,
     private ConvertToJSON: ConvertToJSONService,
     private EncrdecrService: EncrdecrService) { }
  GetToken() {
    let token = sessionStorage.getItem('`huR,8@RK9^??b4 -.hfs!!uR,XX');
    let UserId = this.EncrdecrService.getDecr(sessionStorage.getItem('m5JkoXISmYRAIuY'));
    let User_Type = this.EncrdecrService.getDecr(sessionStorage.getItem('L2D3506kIHSk3E0'));
    let IpAddress = sessionStorage.getItem('Tgsh@#3734fjfskshh==');
    console.log(token + "@" + UserId + "|" + User_Type + "|" + IpAddress);
    const TOKEN = btoa(token + "@" + UserId + "|" + User_Type + "|" + IpAddress);
    const tokenOption = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Basic ${TOKEN}`,
      })
    }
    return tokenOption;
  }
  GetNonJSONToken() {
    let token = sessionStorage.getItem('`huR,8@RK9^??b4 -.hfs!!uR,XX');
    let UserId = this.EncrdecrService.getDecr(sessionStorage.getItem('m5JkoXISmYRAIuY'));
    let User_Type = this.EncrdecrService.getDecr(sessionStorage.getItem('L2D3506kIHSk3E0'));
    let IpAddress = sessionStorage.getItem('Tgsh@#3734fjfskshh==');
    console.log(token + "@" + UserId + "|" + User_Type + "|" + IpAddress);
    const TOKEN = btoa(token + "@" + UserId + "|" + User_Type + "|" + IpAddress);
    const tokenOption = {
      headers: new HttpHeaders({

        'Authorization': `Basic ${TOKEN}`,
      })
    }
    return tokenOption;
  }

  GetTitleList(): Observable<any> {
    let DataUrl = `${this.ServerUrl}/api/PartnerOnboarding/GetMasterData?TRANS_TYPE=ClientTitle`;
    console.log('Title List URL -> '+DataUrl)
    const header = this.GetToken();
    const json = this.ConvertToJSON.QueryStringToJSONWithEncryptedValue(DataUrl.split('?')[1]);

    return this.http.post<any>(DataUrl.split('?')[0],json,header).pipe();
  }
  GetBusinessSecondaryList(Flag): Observable<any> {
    let DataUrl = `${this.ServerUrl}/api/PartnerOnboarding/GetMasterData?TRANS_TYPE=${Flag}`;
    const json = this.ConvertToJSON.QueryStringToJSONWithEncryptedValue(DataUrl.split('?')[1]);
    const header = this.GetToken();
    return this.http.post<any>(DataUrl.split('?')[0],json,header).pipe();
  }
  GetTypeList(): Observable<any> {
    let DataUrl = `${this.ServerUrl}/api/PartnerOnboarding/GetMasterData?TRANS_TYPE=SbType&REFF_ID=CP`;
    const json = this.ConvertToJSON.QueryStringToJSONWithEncryptedValue(DataUrl.split('?')[1]);
    const header = this.GetToken();
    return this.http.post<any>(DataUrl.split('?')[0],json,header).pipe();
  }
  GetCountryList(): Observable<any> {
    let DataUrl = `${this.ServerUrl}/api/PartnerOnboarding/GetMasterData?TRANS_TYPE=COUNRTY_LIST`;
    const json = this.ConvertToJSON.QueryStringToJSONWithEncryptedValue(DataUrl.split('?')[1]);
    const header = this.GetToken();
    return this.http.post<any>(DataUrl.split('?')[0],json,header).pipe();
  }
  BindStateList(): Observable<any> {
    let DataUrl = `${this.ServerUrl}/api/PartnerOnboarding/GetMasterData?TRANS_TYPE=STATE_LIST`;
    const json = this.ConvertToJSON.QueryStringToJSONWithEncryptedValue(DataUrl.split('?')[1]);
    const header = this.GetToken();
    return this.http.post<any>(DataUrl.split('?')[0],json,header).pipe();
  }
  CheckRMCode(PreEmpCode) {
    let DataUrl =`${this.ServerUrl}/api/PartnerOnboarding/GetMasterData?TRANS_TYPE=RMCodeExist&REFF_ID=${PreEmpCode}`
    const json = this.ConvertToJSON.QueryStringToJSONWithEncryptedValue(DataUrl.split('?')[1]);
    const header = this.GetToken();
    return this.http.post<any>(DataUrl.split('?')[0],json,header).pipe();
  }
  CheckPan(TRANS_VALUE) {
    let DataUrl =`${this.ServerUrl}/api/PartnerOnboarding/GetMasterData?TRANS_TYPE=CheckSubBrokerPan&REFF_ID=${TRANS_VALUE}`
    const json = this.ConvertToJSON.QueryStringToJSONWithEncryptedValue(DataUrl.split('?')[1]);
    const header = this.GetToken();
    return this.http.post<any>(DataUrl.split('?')[0],json,header).pipe();

  }

  getStateandCityName(PinCode:string,Flag:string) {

    //////
    let DataUrl =`${this.ServerUrl}/api/FillBank_details/GetDetail?Flag=${Flag}&Code=${PinCode}`
    const json = this.ConvertToJSON.QueryStringToJSON(DataUrl.split('?')[1]);
    const header = this.GetToken();
    return this.http.post<any>(DataUrl.split('?')[0],json,header).pipe();
  }


  PostCPBasicDetails(formData): Observable<any> {
    debugger
    let DataUrl = `${this.ServerUrl}/api/PartnerOnboarding/Details`;
    const header = this.GetToken();
    return this.http.post<any>(DataUrl, formData, header);
  }

  PostCPBasicDetailsDocumnt(formData): Observable<any> {
    let DataUrl = `${this.ServerUrl}/api/PartnerOnboarding/UploadDocument`;
    const header = this.GetNonJSONToken();
    return this.http.post<any>(DataUrl, formData,header);
  }


  SendOTP(formData): Observable<any> {
    let DataUrl = `${this.ServerUrl}/api/OTP_Birla`;
    const header = this.GetToken();
    return this.http.post<any>(DataUrl, formData, header);
  }
  BindAccountType(): Observable<any> {
    let TRANS_TYPE ="";
    if(environment.CompayName == "Mahindra"){
      TRANS_TYPE= 'ACCOUNT_TYPE_MAHINDRA';
    }
    else{
      TRANS_TYPE= 'ACCOUNT_TYPE';
    }
    let DataUrl = `${this.ServerUrl}/api/PartnerOnboarding/GetMasterData?TRANS_TYPE=${TRANS_TYPE}`;
    const json = this.ConvertToJSON.QueryStringToJSONWithEncryptedValue(DataUrl.split('?')[1]);
    const header = this.GetToken();
    return this.http.post<any>(DataUrl.split('?')[0],json,header).pipe();
  }
  GetMasterData(TransType): Observable<any> {
    let DataUrl = `${this.ServerUrl}/api/PartnerOnboarding/GetMasterData?TRANS_TYPE=${TransType}`;
    const json = this.ConvertToJSON.QueryStringToJSONWithEncryptedValue(DataUrl.split('?')[1]);
    const header = this.GetToken();
    return this.http.post<any>(DataUrl.split('?')[0],json,header).pipe();
  }
  GetRelationshipList(): Observable<any> {
    let DataUrl = `${this.ServerUrl}/api/PartnerOnboarding/GetMasterData?TRANS_TYPE=RELATION_LIST`;
    const json = this.ConvertToJSON.QueryStringToJSONWithEncryptedValue(DataUrl.split('?')[1]);
    const header = this.GetToken();
    return this.http.post<any>(DataUrl.split('?')[0],json,header).pipe();
  }

  GETCpDetails(SubBrokerId,TRANS_TYPE): Observable<any> {
    let DataUrl = `${this.ServerUrl}/api/CPOnboarding/GetDetails?TRANS_TYPE=${TRANS_TYPE}&SubBrokerID=${SubBrokerId}`;
    console.log(DataUrl);
    const json = this.ConvertToJSON.QueryStringToJSON(DataUrl.split('?')[1]);
const header = this.GetToken();
    return this.http.post<any>(DataUrl.split('?')[0],json,header).pipe();
  }

  PostCPDetails(formData): Observable<any> {
    let DataUrl = `${this.ServerUrl}/api/SubBrokerEditProfile_Birla/Details`;
    const header = this.GetToken();
    return this.http.post<any>(DataUrl, formData, header);
  }

  // GetCPDocument(SubBrokerId,TRANS_TYPE): Observable<any> {
  //   let DataUrl = `${this.ServerUrl}/api/Distributor_Birla/GetCPDocument?SubBrokerID=${SubBrokerId}&TYPE=${TRANS_TYPE}`;
  //   const header = this.GetToken();
  //   return this.http.get<any>(DataUrl,header).pipe();
  // }
  

  
  // GetCPDocument(SubBrokerId,TRANS_TYPE): Observable<any> {
  //   let DataUrl = `${this.ServerUrl}/api/Distributor_Birla/GetCPDocument?SubBrokerID=${SubBrokerId}&TYPE=${TRANS_TYPE}`;
  //   const QueryString = DataUrl.split('?')[1];// GetToPost
  //   const f_url = DataUrl.split('?')[0];// GetToPost
  //   const body = this.ConvertToJSON.QueryStringToJSONWithEncryptedValue(QueryString);
  //   const header = this.GetToken();
  //   return this.http.post<any>(f_url,body,header).pipe();
  //   //const header = this.GetToken();
  //   //return this.http.get<any>(DataUrl,header).pipe();
  // }
//   getBranchList(): Observable<any> {
//     let DataUrl = `${this.ServerUrl}/api/PartnerOnboarding/GetMasterData?TRANS_TYPE=Branch_master&REFF_ID=&CON_STR=&REFF_ID2=`;
//     const json = this.ConvertToJSON.QueryStringToJSONWithEncryptedValue(DataUrl.split('?')[1]);
// const header = this.GetToken();
//     return this.http.post<any>(DataUrl.split('?')[0],json,header).pipe();
//   }
  // getEmpList(BranchId): Observable<any> {
  //   let DataUrl = `${this.ServerUrl}/api/PartnerOnboarding/GetMasterData?TRANS_TYPE=BRANCH_ROLE_WISE_EMP&REFF_ID=${BranchId}&CON_STR=SMC&REFF_ID2=6`;
  //   const json = this.ConvertToJSON.QueryStringToJSONWithEncryptedValue(DataUrl.split('?')[1]);
  //   const header = this.GetToken();
  //   return this.http.post<any>(DataUrl.split('?')[0],json,header).pipe();
  // }
  getEmpList(): Observable<any> {
    let DataUrl = `${this.ServerUrl}/api/PartnerOnboarding/GetMasterData?TRANS_TYPE=ALL_EMP_FOR_RM_MAP&CON_STR=SMC&REFF_ID2=1`;
    const json = this.ConvertToJSON.QueryStringToJSONWithEncryptedValue(DataUrl.split('?')[1]);
    const header = this.GetToken();
    return this.http.post<any>(DataUrl.split('?')[0],json,header).pipe();
  }
  GetDocumentDetails(SubBrokerId,DocumentId,TransType){
    let DataUrl = `${this.ServerUrl}/api/CPOnboarding/GetDocuments?SubBrokerID=${SubBrokerId}&TRANS_TYPE=${TransType}&SBDocumentId=${DocumentId}`;
const json = this.ConvertToJSON.QueryStringToJSONWithEncryptedValue(DataUrl.split('?')[1]);
const header = this.GetToken();
    return this.http.post<any>(DataUrl.split('?')[0], json,header).pipe();
  }
  SendMailDocument(formData): Observable<any> {
    let DataUrl = `${this.ServerUrl}/api/PartnerOnboarding/Details`;
    const header = this.GetToken();
    return this.http.post<any>(DataUrl, formData, header);
  }
}



