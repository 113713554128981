<div class="modal-header AlertModalHeader">Terms & Conditions</div>
<div class="modal-body AlertModalBody" style="height:450px; overflow-y:scroll">
    <div>
        <br>
        <p><strong><u>1. GENERAL</u></strong></p>
        <p class="mt-1">
            Please go through the following terms and condition of Alankit Limited pertaining
            to facilitating an online mechanism for applying/investing in Fixed Deposit schemes of the various companies
            (“Entities”). The user(s) hereby agree to abide by the following terms and conditions and also the terms and
            conditions of the Fixed Deposit scheme(s) of the Entity(ies).
        </p>
        <br>
        <p><strong><u>2. DATA PROTECTION</u></strong></p>
        <p class="mt-1">
            Any personal information the user supplies to Alankit Limited when using the online mechanism will be used by Alankit Limited
            in accordance with its Privacy Policy, if any.</p>
        <p class="mt-1">
            The online mechanism allows the user to access certain functionality as available to the user on the website
            https://www.alankit.com/ and Mobile Applications provided by Alankit Limited under Apple iOS and Android OS
            Operating Systems.
        </p>
        <br>
        <p><strong><u>3. PROPRIETARY RIGHTS AND LICENCE</u></strong></p>
        <p class="mt-1">
            All trademarks, copyright, database rights and other intellectual property rights of any nature in the
            online
            mechanism are owned either directly by Alankit Limited and/or its licensors.</p>
        <br>
        <p><strong><u>4. CONDITIONS OF PROVIDING ONLINE MECHANISM</u></strong></p>
        <p class="mt-1">
            User shall only use the online mechanism and shall not allow any third party to use the same. The online
            mechanism for applying/investing in the Fixed Deposit scheme of the various Entities is provided to the user
            free of charge for personal and non-commercial use. Alankit Limited reserves the right to amend or withdraw the online
            mechanism, or charge for the same at any time and for any reason whatsoever.</p>
        <p class="mt-1">
            The various Fixed Deposit scheme provided by various Entities and other information therein provided to the
            user through online mechanism is on a best effort basis and is dependent on various factors such as
            technology,
            systems and also on external service providers such as the internet, power components, telecommunication
            providers, software vendors, etc. over which Alankit Limited do not have any control. Neither Alankit Limited nor any of its
            directors, employees and other persons dealing on its behalf would be liable for leakage of any of the
            user’s
            information or any disruption/delay of services. Alankit Limited shall not be liable for any loss and/or damage in any
            manner whatsoever under any circumstances.
        </p>
        <p class="mt-1">
            Alankit Limited is only facilitating the users to decide and apply for the Fixed Deposit scheme of Entities provided
            herein. The decision whether to invest or not is of the user and Alankit Limited shall not be responsible for any
            loss/damages etc. arising out of such decision of the user. The user agrees that the funds which the user
            wants
            to invest in Fixed Deposit scheme of the Entities through this online mechanism is not out of any funds
            acquired by the user by borrowing or accepting from any other person and/or any funds which cannot be
            invested by virtue of provisions of law.
        </p>
        <p class="mt-1">
            The user shall not make Alankit Limited a party to any dispute between the user and the Entities arising out of any
            nature
            of services of the Entities or of any kind whatsoever.
            The user shall also make aware of the terms and conditions of the Fixed Deposit scheme as made available by
            the Entities and provided herein by Alankit Limited including using of the personal details of the user, interest rate,
            maturity value etc. and hereby agrees to abide by the same.
        </p>
        <p class="mt-1">
            The user is aware that the transaction requests placed by the user will be honoured only on successful
            completion of transaction and fund realization by the Entities. The factors beyond human and technical
            control
            may interrupt the transaction and as such neither Alankit Limited nor the Entities guarantee of issuing of Fixed
            Deposit
            Receipt in such instances. In case cases, neither Alankit Limited nor the Entities will be liable and responsible to
            the
            user.</p>
        <p class="mt-1">
            The online mechanism provides access/link to the third party site for payment and other related sites and
            such
            access by the user shall be governed by the terms and conditions of such third party site.
        </p>
        <p class="mt-1">
            If for any reason after making an online payment for Fixed Deposit, the FD advice (FDA)/transaction output
            details are not displayed on the screen, then the user shall check the status of the transaction in the
            report
            section after sometime, before proceeding to invest/deposit again.
        </p>
        <br>
        <p><strong><u>5. AVAILABILITY</u></strong></p>
        <p class="mt-1">
            This online mechanism is available on the websites https://www.alankit.com/ and also can be accessed on
            mobile devices running Apple iOS and Android OS Operating Systems provided by Alankit Limited. Alankit Limited will use
            reasonable efforts to make the online mechanism available at all times and quality and availability of the
            online mechanism may be affected by factors outside Alankit Limited reasonable control.
        </p>
        <p class="mt-1">
            Alankit Limited and its group of companies, its directors, employees, officials and sub-contractors do not accept any
            responsibility whatsoever for unavailability of the online mechanism, or any difficulty or inability to
            download
            or access content or any other communication system failure which may result in the online mechanism being
            unavailable and further shall also not be liable if the contents are not up to date.
        </p>
        <br>
        <p><strong><u>6. TERMINATION</u></strong></p>
        <p class="mt-1">
            Alankit Limited may terminate use of the online mechanism at any time at its sole discretion without any notice.
        </p>
        <br>
        <p><strong><u>7. NO LIABILITY</u></strong></p>
        <p class="mt-1">
            7.1. In no event will Alankit Limited be liable for any direct, indirect, special, punitive, exemplary or consequential
            losses or damages of whatsoever kind arising out of use or access to the online mechanism and/or any dispute
            between the user and the Entities, including loss of profit or the like whether based on breach of contract,
            tort
            (including negligence), product liability or otherwise.
        </p>
        <p class="mt-1">
            7.2. Alankit Limited is not liable for any damage or alteration to user’s equipment including but not limited to
            computer
            equipment, handheld device or mobile telephones as a result of the use of the online mechanism or any
            viruses.

        </p>
        <br>
        <p><strong><u>8. DISCLAIMER OF WARRANTIES</u></strong></p>
        <p class="mt-1">
            MFS hereby disclaims all implied warranties with regard to the online mechanism. The same is provided "as
            is" and "as available" without warranty of any kind. No guarantees are made by Alankit Limited for the accuracy or
            completeness of the contents/information.
        </p>
        <br>
        <p><strong><u>9. GOVERNING LAW AND DISPUTES</u></strong></p>
        <p class="mt-1">
            In case of any dispute, the same shall be governed and construed in accordance with the laws of India and
            shall be subject to the jurisdiction of courts in Mumbai only.
        </p>
    </div>
</div>