import { MobileLogoutModule } from './mobile-logout/mobile-logout.module';
import { LoginNseModule } from './login-nse/login-nse.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';

import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BnNgIdleService } from 'bn-ng-idle';

import { DatePipe } from '@angular/common';

import { AppComponent } from './app.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ConfirmationDialogService } from './services/confirmation-dialog.service';
import { DisabledRightClickDirective } from './directive/disabled-right-click.directive';
import { ClientFilterPipe } from './filters/client-filter.pipe';
import { GroupByWithSumPipe } from './filters/group-by-with-sum.pipe';
import { SharedModule } from './shared/shared.module';
import { ThankYouFDComponent } from './thank-you-fd/thank-you-fd.component';

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    DisabledRightClickDirective,
    ClientFilterPipe,
    GroupByWithSumPipe,
    ThankYouFDComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    LoginNseModule,
    MobileLogoutModule,
    SharedModule,

  ],
  providers: [
    ConfirmationDialogService,
    DatePipe,
    BnNgIdleService
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }
