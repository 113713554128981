import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { EncrdecrService } from 'src/app/Auth/encrdecr.service';
import { CustomLoaderService } from 'src/app/services/custom-loader.service';
import { LoginService } from 'src/app/services/login.service';
import { OtpvariableService } from 'src/app/services/otpvariable.service';
import { TokenExpireService } from 'src/app/services/token-expire.service';
import { TransactionService } from 'src/app/services/transaction.service';
import { environment } from 'src/environments/environment';
declare var $:any;

@Component({
  selector: 'app-transaction-otp',
  templateUrl: './transaction-otp.component.html',
  styleUrls: ['./transaction-otp.component.scss']
})
export class TransactionOtpComponent implements OnInit{
  @ViewChild('ngOtpInput', { static: false }) ngOtpInput: any;
  @Input() OpenModel: boolean;
  @Output() OnOTP: EventEmitter<any> = new EventEmitter()
  @Output() CallResendOTP: EventEmitter<any> = new EventEmitter()
  OTPForm: FormGroup;
  OTPFormSubmitted: boolean = false;
  G_ClientBasicInfoId: string;
  OtpTimer: number = 180;
  OtpTimeRemening: any = "3:00";
  OTP_Timer_Function: any;
  MobileNo: string = '';
  EmailID: string = '';
  CompanyName: string = '';
  IsForVerification : boolean = false;
  isShowCloseButton : boolean = true;
  onlyEmail : boolean = false;
  onlyMobile : boolean = false;
  config = {
    length: 6,
    inputClass: 'OTPBox',
    allowNumbersOnly: true,
    inputStyles: {
      'text-security': 'disc',
      '-webkit-text-security': 'disc',
      '-moz-text-security': 'disc'
    },
    disableAutoFocus: false
  }

   //Added By Devang Mehta On 08/02/2024
   IsShowCartMessage : boolean = false;

   //
   IsMobileEmail : boolean = false
   TaxStatus : string = "";

   constructor(private fb: FormBuilder,
    private Loader: CustomLoaderService,
    private EncrdecrService: EncrdecrService,
    public bsModalRef: BsModalRef,
    private otpVariable: OtpvariableService,
    private LoginService: LoginService,
    public bsModelRef: BsModalRef,
    private Logout: TokenExpireService,
    private transaction : TransactionService) {

    this.OTPForm = this.fb.group({
      OTP: ['', [Validators.required, Validators.minLength(6)]]
    });
  }

  G_SubBrokerId: any
  UserType: any
  ngOnInit() {
    this.EmailID = this.otpVariable.EmailId;
    this.MobileNo = this.otpVariable.MobileNo;
    if(this.EmailID && !this.MobileNo){
      this.onlyEmail = true;
    }
    else if(this.MobileNo && !this.EmailID){
      this.onlyMobile = true;
    }else{
      this.IsMobileEmail = true;
    }
    this.StartTimer();
    this.CompanyName = environment.CompayName;
  }
  ngAfterViewInit() {
    let loaderInterval = setInterval(() => {
      let str = document.readyState;
      if (str == 'complete') {
        let id = $('.ng-otp-input-wrapper .otp-input:first-child').attr('id');
        this.ngOtpInput.focusTo(id);
        clearInterval(loaderInterval)
      }
    }, 1000);
  }
  get o() {
    return this.OTPForm.controls;
  }
  ResetOTP() {
    this.bsModalRef.hide();
    this.ClearIntervalOTP()
  }
  LogoutFunction(){
    this.bsModalRef.hide();
    this.ClearIntervalOTP()
    if (sessionStorage.getItem("b5POtv8EekKYEcn6") !== undefined && sessionStorage.getItem("b5POtv8EekKYEcn6") !== null) {
      this.Logout.LogoutUser();
    }
  }
  ClearIntervalOTP() {
    this.ngOtpInput.setValue('');
    $('#Time').text(this.OtpTimeRemening);

    clearInterval(this.OTP_Timer_Function);
    
  }
  onSubmitOTP() {
    // //testing purpose
    // this.bsModelRef.content.onClose.next(true);
    // this.bsModalRef.hide();
    // this.ClearIntervalOTP();
    let key = sessionStorage.getItem("u22jWb3CPua4Cqa")
    sessionStorage.removeItem("u22jWb3CPua4Cqa")
    debugger
    this.OtpVerification();
    // switch (key) {
    //   case "IsForConsentEUIN":
    //     this.EUINSubmitConsent();
    //     break;
    //   default:
        
        // break;
    // }
  }
  onOtpChange(e) {
    let val = e;
    let v = '';
    v = v + val;
    this.OTPForm.controls["OTP"].setValue(v);
  }
  ResendOTP() {
    // this.CallResendOTP.emit(true);
    this.ClearIntervalOTP();
    this.StartTimer();
    this.bsModalRef.content.action.next(true);
  }
  ValueMasking(value, Flag) {
    let MaskValue = "";
    if (Flag == "Mobile") {
      MaskValue = value.replace(/\d(?=\d{4})/g, "X");
    } else if (Flag == "Email") {
      let index = value.indexOf("@");
      MaskValue =
        value.substring(0, 2) +
        "XXXXXXXX" +
        value.substring(index, value.length); //- 1
    }
    return MaskValue;
  }
  CallAlertModal(msg: any) {
    $("#AlertModalprofilemodels").modal({
      backdrop: 'static',
      keyboard: false
    });
    $('#AlertModalContentmodels').text(msg);
  }
  StartTimer() {
    $(".res").removeClass("act").prop("disabled", true);
    let t = this.OtpTimer;
    this.OTP_Timer_Function = setInterval(function () {
      //
      if (t >= 0) {
        const minutes = Math.floor(t / 60);
        let seconds: any = t % 60;
        if (seconds < 10) {
          seconds = `0${seconds}`;
        }

        $("#Time").text(`0${minutes}:${seconds}`);
        console.log($("#Time").val())
        t -= 1;
        if (t == 0) {
          $(".res").addClass("act").prop("disabled", false);
        }
      }
    }, 1000);
  }
  OtpVerification(){
    if (this.onlyEmail == true) {
      const EmailVerifyRequest = {
        "FullName": "",
        "EmailId": this.EmailID,
        "Trans_Type": "EmailID",
        "Flag": "V",
        "OTP": this.OTPForm.controls["OTP"].value,
        "ActionFrom": this.otpVariable.ActionFrom,
        "TriggerEventName": this.otpVariable.TriggerEventName,
        "ClientBasicinfoId": this.otpVariable.ClientBasicInfoId

      };
      var json = JSON.stringify(EmailVerifyRequest);
      this.LoginService.JMOTPEmail(json).subscribe(
        (res: any) => {
          if (res.Message == "Verified") {
            this.bsModelRef.content.onClose.next(true);
            this.bsModalRef.hide();
            this.ClearIntervalOTP();
          } else if (res.Message == "OTP Code is Expired") {
            this.Loader.hide();
            this.CallAlertModal("OTP Code is Expired")
  
          } else if (res.Message == "User Not Exist") {
  
          } else if (res.Message == "Overlimit") {
            this.Loader.hide();
            this.CallAlertModal("Overlimit!! please try after 5 minutes.");
  
          } else if (res.Message == "Invalid OTP Code") {
            this.Loader.hide();
            this.CallAlertModal("Invalid OTP Code");
  
          } else {
            this.Loader.hide();
            this.CallAlertModal("Verified operation Faild...")
  
          }
        }, (err: any) => {
          console.log(err);
        })
    } else {
      const MobileVerifyRequest = {
        "ClientBasicinfoId": this.otpVariable.ClientBasicInfoId,
        "VERIFICATION_ID": 'EmailSmsOtp',
        "FullName": "",
        "MobileNo": this.MobileNo,
        "EmailID": this.EmailID,
        "Trans_Type": "Mobile",
        "Flag": "V",
        "OTP": this.OTPForm.controls["OTP"].value,
        "ActionFrom": this.otpVariable.ActionFrom,
        "TriggerEventName": this.otpVariable.TriggerEventName,
        "USER_TYPE": 3
      };
      var json = JSON.stringify(MobileVerifyRequest);
      this.LoginService.JMOTPMobile(json).subscribe(
        (res: any) => {
  
          if (res.Message == "Verified") {
            this.bsModelRef.content.onClose.next(true);
            this.bsModalRef.hide();
            this.ClearIntervalOTP();
          } else if (res.Message == "OTP Code is Expired") {
            this.Loader.hide();
            this.CallAlertModal("OTP Code is Expired")
  
          } else if (res.Message == "User Not Exist") {
  
          } else if (res.Message == "Overlimit") {
            this.Loader.hide();
            this.CallAlertModal("Overlimit!! please try after 5 minutes.");
  
          } else if (res.Message == "Invalid OTP Code") {
            this.Loader.hide();
            this.CallAlertModal("Invalid OTP Code");
  
          } else {
            this.Loader.hide();
            this.CallAlertModal("Verified operation Faild...")
  
          }
        }, (err: any) => {
          console.log(err);
        })
    }
  }
  // EUINSubmitConsent(){
  //   debugger
  //   let requestObject = JSON.parse(sessionStorage.getItem('q6q2V43HuQ3ZIU0icGqr'))
  //   this.Loader.show();
  //   let request = {
  //     "EUINNumber":"",
  //     "RequestedEmpCode":"",
  //     "ConsentBy":requestObject.EmpCode,
  //     "OtpString":this.EncrdecrService.setEncr(`${requestObject.Mobile}|${this.OTPForm.controls["OTP"].value}`),
  //     "Flag":"UPDATE",
  //     "ConsentId":requestObject.Id,
  //     "TransactionObject" :{
  //     }
  // }
  //   // this.transaction.CheckEUINConsent(request).subscribe
  //   // ((data) => {
  //   //   debugger
  //   //   this.Loader.hide();
  //   //   if(data && data.Message){
  //   //     if(data.ID && data.ID>0){
  //   //       this.bsModelRef.content.onClose.next(true);
  //   //       this.bsModalRef.hide();
  //   //       this.ClearIntervalOTP();
  //   //     }else{
  //   //       this.CallAlertModal(data.Message)
  //   //     }
  //   //     //this.CallAlertModal(data.Message)
  //   //   }else{
  //   //     this.CallAlertModal("Something went wrong please try again later")
  //   //   }
  //   // }
  //   //   ),((error)=>{
  //   //     this.Loader.hide();
  //   //   })
  // }
}
