
import { Injectable } from '@angular/core';

import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { EncrdecrService } from './../Auth/encrdecr.service';
import { MainHeaderMenuService } from './main-header-menu.service';
@Injectable({
  providedIn: 'root'
})
export class TokenExpireService {
  constructor(private EncrdecrService: EncrdecrService,
    private router: Router, public ShowHideHeaderMenu: MainHeaderMenuService) { }

  LogoutUser() {
    // alert('In LogoutUser Method');
    this.ShowHideHeaderMenu.hide();
    const CompanyName: string = environment.CompayName;
    var G_UserType = parseInt(this.EncrdecrService.getDecr(sessionStorage.getItem('L2D3506kIHSk3E0'))); //Session_UserType
    const LoginSource = sessionStorage.getItem('6+J3RebINEPBJVbAJw');
    let isNSDLSSO = sessionStorage.getItem("SSOLOGIN");
    sessionStorage.clear();
    localStorage.clear();
    this.SetCompanyLogo();

    if (G_UserType == 1) {
      this.router.navigateByUrl("/employee-login")
    }
    else if (G_UserType == 2) {
      this.router.navigateByUrl("/cp-login")
    }
    else {
      // alert('In LogoutUser Method 2');
      if (isNSDLSSO == 'Y' && isNSDLSSO != null && isNSDLSSO != undefined) {
        this.router.navigateByUrl("/NSDLSSOExpire")
      }
      else {
        // alert('In LogoutUser Method 3');
        this.router.navigateByUrl("/login-corp")
      }
    }
  }

  SetCompanyLogo() {
    const CompanyName: string = environment.CompayName;
    switch (CompanyName) {
      case 'JMF':
        sessionStorage.setItem('logo', '../../assets/images/Logo_JM.png');
        sessionStorage.setItem('Abodhj#@jdkd/%^82ncdbcnggshd!@#$', this.EncrdecrService.setEncr("18"));
        sessionStorage.setItem('Abodhj#@jdkd/%^ysudsh&#&shdj@#$', "18");
        sessionStorage.setItem('Mnuoisns6wqgswj2$#', "0");
        break;
      case 'ABML_Money':
        sessionStorage.setItem('logo', '../../assets/images/ABMLband_Logo.jpg');
        sessionStorage.setItem('Abodhj#@jdkd/%^82ncdbcnggshd!@#$', this.EncrdecrService.setEncr("19"));
        sessionStorage.setItem('Abodhj#@jdkd/%^ysudsh&#&shdj@#$', "19");
        sessionStorage.setItem('Mnuoisns6wqgswj2$#', "1");
        break;
      case 'Alankit':
        sessionStorage.setItem('logo', '../../assets/images/Alankit_logo.svg');
        sessionStorage.setItem('Abodhj#@jdkd/%^82ncdbcnggshd!@#$', this.EncrdecrService.setEncr("20"));
        sessionStorage.setItem('Abodhj#@jdkd/%^ysudsh&#&shdj@#$', "20");
        sessionStorage.setItem('Mnuoisns6wqgswj2$#', "2");
        break;
      case 'NSDL':
        sessionStorage.setItem('logo', '../../assets/images/NSDL_Logo.svg');
        sessionStorage.setItem('Abodhj#@jdkd/%^82ncdbcnggshd!@#$', this.EncrdecrService.setEncr("21"));
        sessionStorage.setItem('Abodhj#@jdkd/%^ysudsh&#&shdj@#$', "21");
        sessionStorage.setItem('Mnuoisns6wqgswj2$#', "3");
        break;
    }
  }
}
